import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';

export class DocumentType implements Deserializable {
    public _id: string;
    public name: string;
    public platform: Platform | string;
    public require: boolean;
    public expiryDateRequire: boolean;
   
    constructor(data: any){
        this._id = data._id;
        this.name = data.name;
        this.platform = data.platform;
        this.require = data.require;
        this.expiryDateRequire = data.expiryDateRequire;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}