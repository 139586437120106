import { state } from '@angular/animations';
import { createReducer, on, State } from '@ngrx/store';
import { Platform } from 'src/app/models/platform.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { activatePlatformsAction, activatePlatformsActionFailed, activatePlatformsActionSuccess, addPlatformAction, addPlatformFailedAction, addPlatformSuccessAction, deletePlatformsAction, deletePlatformsActionFailed, deletePlatformsActionSuccess, fetchAllPlatformsAction, fetchAllPlatformsActionFailed, fetchAllPlatformsActionSuccess, modifyPlatformAction, modifyPlatformFailedAction, modifyPlatformSuccessAction, resetFormAction } from '../actions/platforms.actions';

export interface StatePlatforms{
    list: Platform[] | null,
    loading: boolean | null,
    error: ErrorApi | null,
    adding: Platform | null,
    modifying: Platform | null,
    deleting: boolean | null,
    activating: boolean | null,
    errorsForm: ErrorApi | null,
}
 
export const initialState:StatePlatforms  = {
    list: null,
    loading: null,
    error: null,
    adding: null,
    modifying: null,
    deleting: null,
    activating: null,
    errorsForm: null
};
 
const _platformsReducer = createReducer(
  initialState,
  on(fetchAllPlatformsAction, (state) => ({...state, loading: true, list: null, error :null})),
  on(fetchAllPlatformsActionSuccess, (state, payload) =>  ({...state, list: payload.platforms, loading: false})),
  on(fetchAllPlatformsActionFailed, (state, payload) => ({...state, error: payload.error, loading: false })),
  on(addPlatformAction, (state) => ({...state, loading: true, errorsForm: null} )),
  on(addPlatformSuccessAction, (state, payload) => ({...state, loading: false, adding: payload.platform})),
  on(addPlatformFailedAction, (state, payload) => ({...state, loading: false, errorsForm: payload.errors}) ),
  on(modifyPlatformAction, (state) => ({...state, loading: true, errorsForm: null} )),
  on(modifyPlatformSuccessAction, (state, payload) => ({...state, loading: false, modifying: payload.platform})),
  on(modifyPlatformFailedAction, (state, payload) => ({...state, loading: false, errorsForm: payload.errors}) ),
  on(deletePlatformsAction, (state) =>  ({...state, deleting: true })),
  on(deletePlatformsActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deletePlatformsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),
  on(activatePlatformsAction, (state) =>  ({...state, activating: true })),
  on(activatePlatformsActionSuccess, (state) =>  ({...state, activating: false})),
  on(activatePlatformsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, activating: false })),
  on(resetFormAction, (state) => ({...state, adding: null, modifying: null}) ),

);
 
export function platformReducer(state: any, action: any) {
  return _platformsReducer(state, action);
}