import { Deserializable } from "../interfaces/deserializable.interface";
import { User } from "../user.model";
import { MissionJob } from "./missionJob.model";

export class TeamMember implements Deserializable {
    public _id: string;
    public missionId: string;
    public userId?: User;
    public teamId: string;
    public order: string;
    public missionJob: MissionJob;
    public startServiceAt: Date;
    public endServiceAt: Date;
    public isAbsent: boolean;
    public signatureStartUrl: string;
    public signatureEndUrl: string;

    constructor(data: any){
        this._id = data._id;
        this.missionId = data.missionId;
        this.userId = data.userId ? new User(data.userId) : undefined;
        this.teamId = data.teamId
        this.order = data.order;
        this.missionJob = data.missionJob;
        this.startServiceAt = data.startServiceAt;
        this.endServiceAt = data.endServiceAt;
        this.isAbsent = data.isAbsent;
        this.signatureStartUrl = data.signatureStartUrl;
        this.signatureEndUrl = data.signatureEndUrl;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}