import {DataPaginated} from '../../models/utils/data-paginated.model';
import {ErrorApi} from '../../models/utils/error-api.model';
import {createReducer, on} from '@ngrx/store';
import {fetchAllPrepaysAction, fetchAllPrepaysActionFailed, fetchAllPrepaysActionSuccess} from '../actions/prepay.action';

export interface StatePrepays {
  dataPaginated: DataPaginated | null;
  loading: boolean | null;
  errors: ErrorApi | null;
}

export const initialState: StatePrepays  = {
  dataPaginated: null,
  loading: null,
  errors: null
};

const _prepaysReducer = createReducer(
  initialState,
  on(fetchAllPrepaysAction, (state) => ({...state, loading: true, errors: null})),
  on(fetchAllPrepaysActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllPrepaysActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false }))
);

export function prepaysReducer(state: any, action: any) {
  return _prepaysReducer(state, action);
}
