import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
  export class AuthInterceptor implements HttpInterceptor {
  
    constructor(private authService: AuthService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler){
      return next.handle(request).pipe( tap(() => {}, (err:any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
           return;
          }

          this.router.navigate(['auth'])
        }
      }));
    }
  }
  