import {Deserializable} from './interfaces/deserializable.interface';
import {Platform} from './platform.model';

export enum Role {
  superAdmin = 'super-admin',
  admin = 'admin',
  user = 'user'
}

export enum OfferTypes {
  All = 'all',
  Only_manager = 'only_manager',
  Only_no_manager = 'only_no_manager',
  None = 'none'
}

export enum MeasurementsStatus {
  pending = "pending",
  validated = "validated",
  refused = "refused"
}

export class User implements Deserializable {
  public _id: string;
  public email: string;
  public identifiant: string;
  public firstname: string;
  public lastname: string;
  public phoneNumber: string;
  public mobileNumber: string;
  public birthDate: Date;
  public measurementsStatus: MeasurementsStatus;
  public size: number;
  public chestSize: string;
  public trouserSize: string;
  public shoeSize: string;
  public privacyPolicy: boolean;
  public usagePolicy: boolean;
  public avatarUrl: string;
  public role: Role;
  public offersType: OfferTypes;
  public platforms: Platform[];
  public currentPlatform?: Platform | null;
  public createdAt: Date;

  constructor(data: any) {
    this._id = data._id;
    this.email = data.email;
    this.identifiant = data.identifiant;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.phoneNumber = data.phoneNumber;
    this.mobileNumber = data.mobileNumber;
    this.birthDate = data.birthDate;
    this.measurementsStatus = data.measurementsStatus;
    this.size = data.size;
    this.chestSize = data.chestSize;
    this.trouserSize = data.trouserSize;
    this.shoeSize = data.shoeSize;
    this.privacyPolicy = data.privacyPolicy;
    this.usagePolicy = data.usagePolicy;
    this.avatarUrl = data.avatarUrl;
    this.role = data.role;
    this.offersType = data.offersType;
    this.platforms = data.platforms.filter((platform: any) => platform != null).map((platform: Object) => new Platform(platform));
    if (data.currentPlatform) {
      this.currentPlatform = new Platform(data.currentPlatform);
    }
    this.createdAt = data.createdAt;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  getFullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  getRoleName() {
    if (this.role === Role.superAdmin) {
      return 'Super-admin';
    } else if (this.role === Role.admin) {
      return 'Administrateur';
    } else if (this.role === Role.user) {
      return 'Utilisateur';
    }
    return 'Role Inconnu';
  }

  isSuperAdmin() {
    return this.role === Role.superAdmin;
  }
}
