import { createReducer, on, State } from '@ngrx/store';
import { setShowDetails } from '../actions/menu.actions';

export interface StateMenu{
  showDetail: boolean;
}
 
export const initialState = {
  showDetail: true
};
 
const _menuReducer = createReducer(
  initialState,
  on(setShowDetails, (state, payload) => ({...state, showDetail: payload.payload}) ),
);
 
export function menuReducer(state: any, action: any) {
  return _menuReducer(state, action);
}