import { authReducer, StateAuth } from "./auth.reducer";
import { counterReducer } from "./counter.reducer";
import { documentTypeReducer, StateDocumentTypes } from "./document-types.reducer";
import { incidentTypeReducer, StateIncidentTypes } from "./incident-types.reducer";
import { jobReducer, StateJobs } from "./jobs.reducer";
import { menuReducer, StateMenu } from "./menu.reducer";
import { missionReducer, StateMissions } from "./missions.reducer";
import { platformReducer, StatePlatforms } from "./platforms.reducer";
import { sharingReducer, StateSharing } from "./sharing.reducer";
import { skillReducer, StateSkills } from "./skills.reducer";
import { StateTickets, ticketReducer } from "./tickets.reducer";
import { StateUsers, usersReducer } from "./users.reducer";
import {prepaysReducer, StatePrepays} from './prepay.reducer';


export interface AppState{
    count: number;
    menu: StateMenu;
    auth: StateAuth;
    platforms: StatePlatforms;
    skills: StateSkills;
    incidentTypes: StateIncidentTypes;
    jobs: StateJobs;
    documentTypes: StateDocumentTypes;
    missions: StateMissions;
    prepays: StatePrepays;
    sharing: StateSharing;
    users: StateUsers;
    tickets: StateTickets;
}

export const reducers = {
    count : counterReducer,
    menu: menuReducer,
    auth: authReducer,
    platforms: platformReducer,
    skills: skillReducer,
    incidentTypes: incidentTypeReducer,
    jobs: jobReducer,
    documentTypes: documentTypeReducer,
    missions: missionReducer,
    prepays: prepaysReducer,
    sharing: sharingReducer,
    users: usersReducer,
    tickets: ticketReducer
};
