import { MatDialogConfig } from '@angular/material/dialog';
import { createAction, props } from '@ngrx/store';
import { Mission } from 'src/app/models/mission/mission.model';
import { MissionApplied } from 'src/app/models/mission/missionApplied.model';
import { MissionJob } from 'src/app/models/mission/missionJob.model';
import { Team } from 'src/app/models/mission/team.model';
import { TeamMember } from 'src/app/models/mission/teamMember.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllMissionsAction = createAction(
    '[Missions] Get all missions - Loading...',
    props<{currentPage: number, limit: number, isHistory?: string, missionStatus?: string}>()
);

export const fetchAllMissionsActionSuccess = createAction(
    '[Missions]  Get all missions - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllMissionsActionFailed = createAction(
    '[Missions]  Get all missions - Failed',
    props<{errors: ErrorApi}>()
);

export const addMissionsAction = createAction(
    '[Missions]  Add mission - Adding...',
    props<{missionDto: any}>()
);

export const addMissionsActionSuccess = createAction(
    '[Missions]  Add mission - Success',
    props<{edited_mission: Mission}>()
);

export const addMissionsActionFailed = createAction(
    '[Missions]  Add mission - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyMissionsAction = createAction(
    '[Missions]  Modify mission - Modifying...',
    props<{missionId: string, missionDto: any}>()
);

export const modifyMissionsActionSuccess = createAction(
    '[Missions]  Modify mission - Success',
);

export const modifyMissionsActionFailed = createAction(
    '[Missions]  Modify mission - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteMissionsAction = createAction(
    '[Missions]  Delete mission - Deleting...',
    props<{missionId: string}>()
);

export const deleteMissionsActionSuccess = createAction(
    '[Missions]  Delete mission - Success',
);

export const deleteMissionsActionFailed = createAction(
    '[Missions]  Delete mission - Failed',
    props<{errors: ErrorApi}>()
);

export const editMissionsAction = createAction(
    '[Missions]  Edit missions',
    props<{number: number, edited_mission: Mission}>()
);

export const newMissionsAction = createAction(
    '[Missions]  New mission'
);

export const editNumberAction = createAction(
    '[Missions]  Change number',
    props<{number: number}>()
);

export const fetchAllMissionsJobAction = createAction(
    '[Missions] Get all jobs in a mission - Loading...',
    props<{missionId: string}>()
);

export const fetchAllMissionsJobActionSuccess = createAction(
    '[Missions]  Get all jobs in a mission - Success',
    props<{data: MissionJob[]}>()
);

export const fetchAllMissionsJobActionFailed = createAction(
    '[Missions]  Get all jobs in a mission - Failed',
    props<{errors: ErrorApi}>()
);

export const addMissionsJobAction = createAction(
    '[Missions]  Add job in mission - Adding...',
    props<{missionId: string, missionJobDto: any}>()
);

export const addMissionsJobActionSuccess = createAction(
    '[Missions]  Add job in mission - Success',
);

export const addMissionsJobActionFailed = createAction(
    '[Missions]  Add job in mission - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyMissionsJobAction = createAction(
    '[Missions]  Modify job in a mission - Modifying...',
    props<{missionId: string, missionJobId: string, missionJobDto: any}>()
);

export const modifyMissionsJobActionSuccess = createAction(
    '[Missions]  Modify job in a mission - Success',
);

export const modifyMissionsJobActionFailed = createAction(
    '[Missions]  Modify job in a mission - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteMissionsJobAction = createAction(
    '[Missions]  Delete job in a mission - Deleting...',
    props<{missionId: string, missionJobId: string}>()
);

export const deleteMissionsJobActionSuccess = createAction(
    '[Missions]  Delete job in a mission - Success',
);

export const deleteMissionsJobActionFailed = createAction(
    '[Missions]  Delete job in a mission - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllMissionsAppliedAction = createAction(
    '[Missions] Get all applications for a missionJob - Loading...',
    props<{missionId: string, missionJobId: string}>()
);

export const fetchAllMissionsAppliedActionSuccess = createAction(
    '[Missions]  Get all applications for a missionJob - Success',
    props<{data: MissionApplied[]}>()
);

export const fetchAllMissionsAppliedActionFailed = createAction(
    '[Missions]  Get all applications for a missionJob - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllTeamAction = createAction(
    '[Missions] Get all teams - Loading...',
    props<{missionId: string}>()
);

export const fetchAllTeamActionSuccess = createAction(
    '[Missions]  Get all teams - Success',
    props<{data: Team[]}>()
);

export const fetchAllTeamMemberActionFailed = createAction(
    '[Missions]  Get all teams - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllTeamMemberAction = createAction(
    '[Missions] Get all team members - Loading...',
    props<{missionId: string}>()
);

export const fetchAllTeamMemberActionSuccess = createAction(
    '[Missions]  Get all team members - Success',
    props<{data: TeamMember[]}>()
);

export const fetchAllTeamActionFailed = createAction(
    '[Missions]  Get all team members - Failed',
    props<{errors: ErrorApi}>()
);

export const openMissionJobDialog = createAction(
    '[Missions]  Open MissionJob Dialog',
    props<{dialogConfig: MatDialogConfig}>()
);

export const closeMissionJobDialog = createAction(
    '[Missions]  Close MissionJob Dialog',
);

export const openNotesDialog = createAction(
    '[Missions]  Open Note Dialog',
    props<{dialogConfig: MatDialogConfig}>()
);

export const closeNotesDialog = createAction(
    '[Missions]  Close Note Dialog',
);

export const numberMissionAppliedDeclined = createAction(
    '[Missions]  Show if there is one missionApplied declined',
    props<{existDeclined: boolean}>()
); 
