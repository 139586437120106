import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { Platform } from 'src/app/models/platform.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { PlatformService } from 'src/app/services/platforms.service';
import * as fromRoot from '../reducers';
import { activatePlatformsAction, activatePlatformsActionFailed, activatePlatformsActionSuccess, addPlatformAction, addPlatformFailedAction, addPlatformSuccessAction, deletePlatformsAction, deletePlatformsActionFailed, deletePlatformsActionSuccess, fetchAllPlatformsAction, fetchAllPlatformsActionFailed, fetchAllPlatformsActionSuccess, modifyPlatformAction, modifyPlatformSuccessAction } from '../actions/platforms.actions';
import { fetchAllJobsActionFailed } from '../actions/jobs.actions';
 
@Injectable()
export class PlatformsEffect {

  constructor(
    private actions$: Actions,
    private platformService: PlatformService,
    private store$: Store<fromRoot.AppState>
  ) {}

  fetchAllPlatformsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllPlatformsAction),
      exhaustMap(action => 
        this.platformService.all().pipe(
          map( (platforms: Platform[]) =>  fetchAllPlatformsActionSuccess({platforms: platforms})) ,
          catchError( () => of(fetchAllPlatformsActionFailed({error: {msg: "Impossible de récupérer les plateformes !"}})))
        )
      )
    )
  );

 
  addPlatformAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPlatformAction),
      exhaustMap(action => 
        this.platformService.create(action.data).pipe(
          map( (platform: Platform) => addPlatformSuccessAction({platform: platform})),
          catchError( (error) => of(addPlatformFailedAction({errors: extractErrors(error)}) ))
        )
      )
    )
  );


  modifyPlatformAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyPlatformAction),
      exhaustMap(action => 
        this.platformService.modify(action.platformId, action.data).pipe(
          map( (platform: Platform) => modifyPlatformSuccessAction({platform: platform}),
          catchError( (error) => of(addPlatformFailedAction({errors: extractErrors(error)}) ))
        ))
      )
    )
  );

  deletePlatformsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePlatformsAction),
      exhaustMap(action => 
        this.platformService.delete(action.platformId).pipe(
          map( () =>  deletePlatformsActionSuccess()) ,
          catchError( (error) => of(deletePlatformsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllPlatformsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deletePlatformsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.platformService.all()
          .pipe(
            map( () =>  fetchAllPlatformsAction()),
            catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );

  activatePlatformsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activatePlatformsAction),
      exhaustMap(action => 
        this.platformService.activate(action.platformId).pipe(
          map( () =>  activatePlatformsActionSuccess()) ,
          catchError( (error) => of(activatePlatformsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  activateAllPlatformsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(activatePlatformsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.platformService.all()
          .pipe(
            map( () =>  fetchAllPlatformsAction()),
            catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );
}