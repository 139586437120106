import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DataPaginated } from '../models/utils/data-paginated.model';
import { Job } from '../models/job.model';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) {}

  all(currentPage: number, limit: number, startDate?: string){

    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if (startDate) params = params.append('startDate', startDate);

    return this.http.get(`${environment.apiUrl}api/v1/jobs`, {params: params})
      .pipe(
        map( (jobs_paginated: any) => {
          const {docs, ...others} = jobs_paginated
          return new DataPaginated(
            others,
            docs.map( (job:any) => new Job(job))
          );
        }),
      );
  }

  add(name: string, isManager: boolean){
    return this.http.post(`${environment.apiUrl}api/v1/jobs`, {jobname: name, isManager: isManager})
      .pipe(
        map( (job: any) => {
          return new Job(job)
        })
      )
  }

  modify(name: string, isManager: boolean, jobId: string){
    return this.http.put(`${environment.apiUrl}api/v1/jobs/${jobId}`, {jobname: name, isManager: isManager})
      .pipe(
        map( (job: any) => {
          return new Job(job)
        })
      )
  }

  delete(jobId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/jobs/${jobId}`)
  }
}
