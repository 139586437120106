import { MatDialogConfig } from '@angular/material/dialog';
import { createReducer, on } from '@ngrx/store';
import { closeConfirmDialog, openConfirmDialog, openConfirmDialogOf } from '../actions/sharing.actions';

export interface StateSharing{
  showConfirmDialog: boolean;
  dialogConfig: MatDialogConfig;
}
 
export const initialState = {
  showConfirmDialog: false,
  dialogConfig: new MatDialogConfig()
};
 
const _sharingReducer = createReducer(
  initialState,
  on(openConfirmDialog, (state) => ({...state, showConfirmDialog: true })),
  on(openConfirmDialogOf, (state,payload) => ({...state, dialogConfig: payload.dialogConfig, showConfirmDialog: true })),
  on(closeConfirmDialog, (state) => ({...state, showConfirmDialog: false })),
);
 
export function sharingReducer(state: any, action: any) {
  return _sharingReducer(state, action);
}