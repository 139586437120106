import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DocumentType } from '../models/document-type.model';
import { DataPaginated } from '../models/utils/data-paginated.model';
import { DocumentTypeUser } from '../models/document-type-user.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  constructor(private http: HttpClient) {}

  all(currentPage: number, limit: number, startDate?: string){

    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if (startDate) {params = params.append('startDate', startDate); }

    return this.http.get(`${environment.apiUrl}api/v1/documents-types`, {params: params})
      .pipe(
        map( (documentTypes_paginated: any) => {
          const {docs, ...others} = documentTypes_paginated
          return new DataPaginated(
            others,
            docs.map( (documentType:any) => new DocumentType(documentType))
          );
        }),
      );
  }

  allDocumentTypeUser(currentPage: number, limit: number, status?: string, documentType?: string, search?: string, userId?: string, startDate?: string){

    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if(status){
      params = params.append('status', status);
    }
    if(documentType){
      params = params.append('documentType', documentType);
    }
    if(search){
      params = params.append('search', search);
    }
    if(userId){
      params = params.append('user', userId);
    }
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    return this.http.get(`${environment.apiUrl}api/v1/documents-types/documents-types-users`, {params: params})
      .pipe(
        map( (documentTypeUsers_paginated: any) => {
          const {docs, ...others} = documentTypeUsers_paginated
          return new DataPaginated(
            others,
            docs.map( (documentTypeUser:any) => new DocumentTypeUser(documentTypeUser))
          );
        }),
      );
  }

  accept(documentTypeUserId: string){
    return this.http.post(`${environment.apiUrl}api/v1/documents-types/documents-types-users/${documentTypeUserId}`, {})
      .pipe(
        map( (documentTypeUser: any) => {
          return new DocumentTypeUser(documentTypeUser)
        })
      )
  }

  reject(documentTypeUserId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/documents-types/documents-types-users/${documentTypeUserId}`)
      .pipe(
        map( (documentTypeUser: any) => {
          return new DocumentTypeUser(documentTypeUser)
        })
      )
  }

  add(name: string, require: boolean, expiryDateRequire: boolean){
    return this.http.post(`${environment.apiUrl}api/v1/documents-types`, {name: name, require: require, expiryDateRequire: expiryDateRequire})
      .pipe(
        map( (documentType: any) => {
          return new DocumentType(documentType)
        })
      )
  }

  modify(name: string, require: boolean, expiryDateRequire:boolean, documentTypeId: string){
    return this.http.put(`${environment.apiUrl}api/v1/documents-types/${documentTypeId}`, {name: name, require: require, expiryDateRequire: expiryDateRequire})
      .pipe(
        map( (documentType: any) => {
          return new DocumentType(documentType)
        })
      )
  }

  delete(documentTypeId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/documents-types/${documentTypeId}`)
  }
}
