import { HttpErrorResponse } from "@angular/common/http";
import { ErrorApi } from "../models/utils/error-api.model";

export function extractErrors(response: HttpErrorResponse){
    let errorApi = {
        msg: null,
        errors: [],
    };

    if(response.error.msg){
        errorApi.msg = response.error.msg;
    }

    if(response.error.message && Array.isArray(response.error.message)){
        errorApi.errors = response.error.message.flatMap( (field: any) => field.errors.map( (error:any) => error))
    }

    return new ErrorApi(errorApi);
}