import { Deserializable } from "../interfaces/deserializable.interface";
import { User } from "../user.model";

export class Team implements Deserializable {
    public _id: string;
    public missionId: string;
    public user: User | null;
    public order: string;
    public incidentIds: string[];
    public rapport: string;

    constructor(data: any){
        this._id = data._id;
        this.missionId = data.missionId;
        this.user = data.user ? new User(data.user) : null;
        this.order = data.order;
        this.incidentIds = data.incidentIds;
        this.rapport = data.rapport;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}