import { EmailValidator } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import {UserPermission} from '../../models/user-permission.model';


interface ILoginAction{
    email: string,
    password: string
}
export const loginAction = createAction(
    '[Auth] Login',
    props<{payload: ILoginAction}>()
);

export const loginActionSuccess = createAction(
    '[Auth] Login success',
    props<{accessToken: string, expiresIn: number, permissions: UserPermission[]}>()
)

export const loginActionFailed = createAction(
    '[Auth] Login failed',
    props<{errors: ErrorApi}>()
)

export const getUserAction = createAction('[Auth] Get User');

export const getUserSuccessAction = createAction(
    '[Auth] Get User Success',
    props<any>()
);

export const getUserFailAction = createAction(
    '[Auth] Get User Failed'
);

export const getPermissionSuccessAction = createAction(
  '[Auth] Get User Permissions Success',
  props<any>()
);

export const getPermissionFailAction = createAction(
  '[Auth] Get User Permissions Failed'
);

export const getTokenAction = createAction(
    '[Auth] Get Token',
    props<{email: string}>()
)

export const getTokenActionSuccess = createAction(
    '[Auth] Get Token Success',
    props<{accessToken: string, expiresIn: number}>()
)

export const getTokenActionFailed = createAction(
    '[Auth] Get Token Failed',
    props<{errors: ErrorApi}>()
)

export const logoutAction = createAction(
    '[Auth] Logout'
)

export const resetPasswordAction = createAction(
    '[Auth] Reset Password',
    props<{email: string, token: string, password: string, confirmPassword: string}>()
)

export const resetPasswordActionSuccess = createAction(
    '[Auth] Reset Password Success',
)

export const resetPasswordActionFailed = createAction(
    '[Auth] Reset Password Failed',
    props<{errors: ErrorApi}>()
)
