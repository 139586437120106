import { createAction, props } from '@ngrx/store';
import { Job } from 'src/app/models/job.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllJobsAction = createAction(
    '[Jobs] Get all jobs - Loading...',
    props<{currentPage: number, limit: number}>()
);

export const fetchAllJobsActionSuccess = createAction(
    '[Jobs]  Get all jobs - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllJobsActionFailed = createAction(
    '[Jobs]  Get all jobs - Failed',
    props<{errors: ErrorApi}>()
);

export const addJobsAction = createAction(
    '[Jobs]  Add job - Adding...',
    props<{name: string, isManager: boolean}>()
);

export const addJobsActionSuccess = createAction(
    '[Jobs]  Add job - Success',
);

export const addJobsActionFailed = createAction(
    '[Jobs]  Add job - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyJobsAction = createAction(
    '[Jobs]  Modify job - Modifying...',
    props<{name: string, isManager: boolean, jobId: string}>()
);

export const modifyJobsActionSuccess = createAction(
    '[Jobs]  Modify job - Success',
);

export const modifyJobsActionFailed = createAction(
    '[Jobs]  Modify job - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteJobsAction = createAction(
    '[Jobs]  Delete job - Deleting...',
    props<{jobId: string}>()
);

export const deleteJobsActionSuccess = createAction(
    '[Jobs]  Delete job - Success',
);

export const deleteJobsActionFailed = createAction(
    '[Jobs]  Delete job - Failed',
    props<{errors: ErrorApi}>()
);

export const openJobDialog = createAction(
    '[Jobs]  Open Job Dialog',
);

export const closeJobDialog = createAction(
    '[Jobs]  Close Job Dialog',
);