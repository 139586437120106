import { createReducer, on } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { acceptDocumentTypeUsersAction, acceptDocumentTypeUsersActionFailed, acceptDocumentTypeUsersActionSuccess, addDocumentTypesAction, addDocumentTypesActionFailed, addDocumentTypesActionSuccess, closeDocumentTypeDialog, deleteDocumentTypesAction, deleteDocumentTypesActionFailed, deleteDocumentTypesActionSuccess, fetchAllDocumentTypesAction, fetchAllDocumentTypesActionFailed, fetchAllDocumentTypesActionSuccess, fetchAllDocumentTypeUsersAction, fetchAllDocumentTypeUsersActionFailed, fetchAllDocumentTypeUsersActionSuccess, modifyDocumentTypesAction, modifyDocumentTypesActionFailed, modifyDocumentTypesActionSuccess, openDocumentTypeDialog, rejectDocumentTypeUsersAction, rejectDocumentTypeUsersActionFailed, rejectDocumentTypeUsersActionSuccess } from '../actions/document-types.actions';

export interface StateDocumentTypes{
    dataPaginated: DataPaginated | null,
    documentTypeUsersData: DataPaginated | null,
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    adding: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    showDocumentTypeDialog: boolean,
}
 
export const initialState:StateDocumentTypes  = {
    dataPaginated: null,
    documentTypeUsersData: null,
    loading: null,
    errors: null,
    errorsDialog: null,
    adding: null,
    modifying: null,
    deleting: null,
    showDocumentTypeDialog: false,
};
  
const _documentTypesReducer = createReducer(
  initialState,
  on(fetchAllDocumentTypesAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllDocumentTypesActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllDocumentTypesActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addDocumentTypesAction, (state) =>  ({...state, adding: true })),
  on(addDocumentTypesActionSuccess, (state) =>  ({...state, showDocumentTypeDialog: false, adding: false})),
  on(addDocumentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(modifyDocumentTypesAction, (state) =>  ({...state, modifying: true })),
  on(modifyDocumentTypesActionSuccess, (state) =>  ({...state, showDocumentTypeDialog: false, modifying: false})),
  on(modifyDocumentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteDocumentTypesAction, (state) =>  ({...state, deleting: true })),
  on(deleteDocumentTypesActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteDocumentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(fetchAllDocumentTypeUsersAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllDocumentTypeUsersActionSuccess, (state, payload) =>  ({...state, documentTypeUsersData: payload.dataPaginated, loading: false})),
  on(fetchAllDocumentTypeUsersActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(acceptDocumentTypeUsersAction, (state) =>  ({...state, adding: true })),
  on(acceptDocumentTypeUsersActionSuccess, (state) =>  ({...state, adding: false})),
  on(acceptDocumentTypeUsersActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(rejectDocumentTypeUsersAction, (state) =>  ({...state, deleting: true })),
  on(rejectDocumentTypeUsersActionSuccess, (state) =>  ({...state, deleting: false})),
  on(rejectDocumentTypeUsersActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),
  
  on(openDocumentTypeDialog, (state) => ({...state, showDocumentTypeDialog: true })),
  on(closeDocumentTypeDialog, (state) => ({...state, showDocumentTypeDialog: false, errorsDialog: null })),
);
 
export function documentTypeReducer(state: any, action: any) {
  return _documentTypesReducer(state, action);
}