import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';
import { User } from './user.model';

export enum Status {
    all = 'all',
    pending = 'pending',
    validated = 'validated',
    declined = 'declined',
  }

export class SkillUser implements Deserializable {
    public _id: string;
    public userId: User;
    public skill: Platform | string;
    public skillFilePath: string;
    public skillDocumentUrl: string;
    public status: string;
    public graduationDate: Date;
    public createdAt: Date;


    constructor(data: any){
        this._id = data._id;
        this.userId = data.userId;
        this.skill = data.skill;
        this.skillFilePath = data.skillFilePath;
        this.skillDocumentUrl = data.skillDocumentUrl;
        this.status = data.status;
        this.graduationDate = data.graduationDate;
        this.createdAt = data.createdAt;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}