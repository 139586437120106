import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {UserPermission} from '../models/user-permission.model';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  constructor(private http: HttpClient) {
  }

  getUserPermissionOfCurrentPlatform(id: string) {
    return this.http.get(`${environment.apiUrl}api/v1/users/${id}/permissions`).pipe(
      map((userPermission: any) => {
        return userPermission
          ? new UserPermission(userPermission)
          : null;
      })
    );
  }

  create(id: string, body: any) {
    return this.http.post(`${environment.apiUrl}api/v1/users/${id}/permissions`, body).pipe(
      map((userPermission: any) => {
        return new UserPermission(userPermission);
      })
    );
  }

  modify(id: string, body: any) {
    return this.http.patch(`${environment.apiUrl}api/v1/users/${id}/permissions`, body).pipe(
      map((userPermission: any) => {
        return new UserPermission(userPermission);
      })
    );
  }
}
