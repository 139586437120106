import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { Skill } from 'src/app/models/skill.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { SkillService } from 'src/app/services/skills.service';
import * as fromRoot from '../reducers';
import { acceptSkillUsersAction, acceptSkillUsersActionFailed, acceptSkillUsersActionSuccess, addSkillUsersAction, addSkillUsersActionSuccess, addSkillUsersActionFailed, addSkillsAction, addSkillsActionFailed, addSkillsActionSuccess, deleteSkillsAction, deleteSkillsActionFailed, deleteSkillsActionSuccess, fetchAllSkillsAction, fetchAllSkillsActionFailed, fetchAllSkillsActionSuccess, fetchAllSkillUsersAction, fetchAllSkillUsersActionFailed, fetchAllSkillUsersActionSuccess, modifySkillsAction, modifySkillsActionFailed, modifySkillsActionSuccess, rejectSkillUsersAction, rejectSkillUsersActionFailed, rejectSkillUsersActionSuccess } from '../actions/skills.actions';

@Injectable()
export class SkillsEffect {

  fetchAllSkillsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllSkillsAction),
      exhaustMap(action => 
        this.skillService.all(action.currentPage, action.limit).pipe(
          map( (skillsPaginated: DataPaginated) =>  {
              return fetchAllSkillsActionSuccess({dataPaginated: skillsPaginated})}) ,
          catchError( (error) => of(fetchAllSkillsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllSkillUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllSkillUsersAction),
      exhaustMap(action => 
        this.skillService.allSkillUser(action.currentPage, action.limit, action.status, action.skillType, action.search).pipe(
          map( (skillUsersPaginated: DataPaginated) =>  {
              return fetchAllSkillUsersActionSuccess({dataPaginated: skillUsersPaginated})}) ,
          catchError( (error) => of(fetchAllSkillUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addSkillsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkillsAction),
      exhaustMap(action => 
        this.skillService.add(action.name).pipe(
          map( () =>  addSkillsActionSuccess()) ,
          catchError( (error) => of(addSkillsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllSkillsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkillsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.skillService.all(1, state.skills.dataPaginated?.limit ? state.skills.dataPaginated?.limit : 1).pipe(
            map( (skillsPaginated: DataPaginated) =>  {
              return fetchAllSkillsActionSuccess({
                dataPaginated: skillsPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillsActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  acceptSkillUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptSkillUsersAction),
      exhaustMap(action => 
        this.skillService.accept(action.skillUserId).pipe(
          map( () =>  acceptSkillUsersActionSuccess()) ,
          catchError( (error) => of(acceptSkillUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  acceptAllSkillUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptSkillUsersActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.skillService.allSkillUser(state.skills.dataPaginated?.page ? state.skills.dataPaginated?.page : 1, state.skills.dataPaginated?.limit ? state.skills.dataPaginated?.limit : 1).pipe(
            map( (skillUsersPaginated: DataPaginated) =>  {
              return fetchAllSkillUsersActionSuccess({
                dataPaginated: skillUsersPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillUsersActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );
  
  addSkillUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkillUsersAction),
      exhaustMap(action => 
        this.skillService.addSkillUser(action.skillUserId, action.userId, action.graduationDate).pipe(
          map( () =>  addSkillUsersActionSuccess()) ,
          catchError( (error) => of(addSkillUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addSkillUsersActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkillUsersActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.skillService.allSkillUser(1, 100).pipe(
            map( (skillUsersPaginated: DataPaginated) =>  {
              return fetchAllSkillUsersActionSuccess({
                dataPaginated: skillUsersPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillUsersActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  modifySkillsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifySkillsAction),
      exhaustMap(action => 
        this.skillService.modify(action.name, action.skillId).pipe(
          map( () =>  modifySkillsActionSuccess()) ,
          catchError( (error) => of(modifySkillsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllSkillsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifySkillsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.skillService.all(state.skills.dataPaginated?.page ? state.skills.dataPaginated.page : 1, state.skills.dataPaginated?.limit ? state.skills.dataPaginated.limit : 1).pipe(
            map( (skillsPaginated: DataPaginated) =>  {
              return fetchAllSkillsActionSuccess({
                dataPaginated: skillsPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillsActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  deleteSkillsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSkillsAction),
      exhaustMap(action => 
        this.skillService.delete(action.skillId).pipe(
          map( () =>  deleteSkillsActionSuccess()) ,
          catchError( (error) => of(deleteSkillsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllSkillsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteSkillsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.skillService.all(state.skills.dataPaginated?.page ? ((state.skills.dataPaginated?.page == state.skills.dataPaginated?.totalPages) && (state.skills.dataPaginated?.docs.length == 1) ? state.skills.dataPaginated?.page - 1 : state.skills.dataPaginated?.page) : 1,
                              state.skills.dataPaginated?.limit ? state.skills.dataPaginated.limit : 1)
          .pipe(
            map( (skillsPaginated: DataPaginated) =>  {
              return fetchAllSkillsActionSuccess({
                dataPaginated: skillsPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );

  rejectSkillUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectSkillUsersAction),
      exhaustMap(action => 
        this.skillService.reject(action.skillUserId).pipe(
          map( () =>  rejectSkillUsersActionSuccess()) ,
          catchError( (error) => of(rejectSkillUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  rejectAllSkillUsersSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(rejectSkillUsersActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.skillService.allSkillUser(state.skills.dataPaginated?.page ? ((state.skills.dataPaginated?.page == state.skills.dataPaginated?.totalPages) && (state.skills.dataPaginated?.docs.length == 1) ? state.skills.dataPaginated?.page - 1 : state.skills.dataPaginated?.page) : 1,
                              state.skills.dataPaginated?.limit ? state.skills.dataPaginated.limit : 1)
          .pipe(
            map( (skillUsersPaginated: DataPaginated) =>  {
              return fetchAllSkillUsersActionSuccess({
                dataPaginated: skillUsersPaginated
              })
            }),
            catchError( (error) => of(fetchAllSkillUsersActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );
 
  constructor(
    private actions$: Actions,
    private skillService: SkillService,
    private store$: Store<fromRoot.AppState>
  ) {}
}