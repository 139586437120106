import { createAction, props } from '@ngrx/store';
import { IFormPlatform, Platform } from 'src/app/models/platform.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllPlatformsAction = createAction(
    '[Platforms] Get all platforms - Loading...'
);

export const fetchAllPlatformsActionSuccess = createAction(
    '[Platforms]  Get all platforms - Success',
    props<{platforms: Platform[]}>()
);

export const fetchAllPlatformsActionFailed = createAction(
    '[Platforms]  Get all platforms - Failed',
    props<{error: ErrorApi}>()
);

export const addPlatformAction = createAction(
    '[Platforms]  Adding...',
    props<{data: IFormPlatform}>()
);

export const addPlatformSuccessAction = createAction(
    '[Platforms]  Adding - Success',
    props<{platform: Platform}>()
);

export const addPlatformFailedAction = createAction(
    '[Platforms]  Adding - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyPlatformAction = createAction(
    '[Platforms]  Modifying...',
    props<{platformId: string, data: any}>()
);

export const modifyPlatformSuccessAction = createAction(
    '[Platforms]  Modifying - Success',
    props<{platform: Platform}>()
);

export const modifyPlatformFailedAction = createAction(
    '[Platforms]  Modifying - Failed',
    props<{errors: ErrorApi}>()
);

export const deletePlatformsAction = createAction(
    '[Platforms]  Delete platform - Deleting...',
    props<{platformId: string}>()
);

export const deletePlatformsActionSuccess = createAction(
    '[Platforms]  Delete platform - Success',
);

export const deletePlatformsActionFailed = createAction(
    '[Platforms]  Delete platform - Failed',
    props<{errors: ErrorApi}>()
);

export const activatePlatformsAction = createAction(
    '[Platforms]  Activate platform - Activating...',
    props<{platformId: string}>()
);

export const activatePlatformsActionSuccess = createAction(
    '[Platforms]  Activate platform - Success',
);

export const activatePlatformsActionFailed = createAction(
    '[Platforms]  Activate platform - Failed',
    props<{errors: ErrorApi}>()
);

export const resetFormAction = createAction(
    '[Platforms]  Adding - Reset',
)