import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { AuthService } from 'src/app/services/auth.service';
import {
  getUserAction,
  getUserSuccessAction,
  getUserFailAction,
  loginAction,
  loginActionSuccess,
  loginActionFailed,
  getTokenAction,
  resetPasswordAction,
  resetPasswordActionSuccess,
  resetPasswordActionFailed,
  getTokenActionSuccess,
  getTokenActionFailed,
  getPermissionSuccessAction, getPermissionFailAction
} from '../actions/auth.actions';
import {UserPermission} from '../../models/user-permission.model';

@Injectable()
export class AuthEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAction),
      exhaustMap(action => 
        this.authService.getUser().pipe(
          map( (user:any) => getUserSuccessAction(user) ),
          catchError( () => of(getUserFailAction()) )
        )
      )
    )
  );

  getPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccessAction),
      exhaustMap(action =>
        this.authService.getPermissions().pipe(
          map((userPermissions: UserPermission[]) => getPermissionSuccessAction({permissions: userPermissions})),
          catchError(() => of(getPermissionFailAction()))
        )
      )
    )
  );

  postLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      exhaustMap(action =>
      {
        return this.authService.login(action)
          .pipe(
            map( (res: any) =>  loginActionSuccess(res)),
            catchError( (error) =>  of(loginActionFailed( {errors: extractErrors(error)} )) )
          )

        }
      )
    )
  );

  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTokenAction),
      exhaustMap(action =>
      {
        return this.authService.resetToken(action.email)
          .pipe(
            map( (res: any) =>  getTokenActionSuccess(res)),
            catchError( (error) =>  of(getTokenActionFailed( {errors: extractErrors(error)} )) )
          )

        }
      )
    )
  );

  resetPassword$ = createEffect(() =>
  this.actions$.pipe(
    ofType(resetPasswordAction),
    exhaustMap(action =>
    {
      return this.authService.resetPassword(action.email, action.token, action.password, action.confirmPassword)
        .pipe(
          map( (res: any) =>  resetPasswordActionSuccess()),
          catchError( (error) =>  of(resetPasswordActionFailed( {errors: extractErrors(error)} )) )
        )

      }
    )
  )
);

  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {}
}
