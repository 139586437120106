import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Ticket } from '../models/ticket.model';
import { DataPaginated } from '../models/utils/data-paginated.model';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) {}

  all(currentPage: number, limit: number, status: string, startDate?: string){
    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    params = params.append('status', status);
    if (startDate) {params = params.append('startDate', startDate); }

    return this.http.get(`${environment.apiUrl}api/v1/tickets`, {params: params})
      .pipe(
        map( (tickets_paginated: any) => {
          const {docs, ...others} = tickets_paginated
          return new DataPaginated(
            others,
            docs.map( (ticket:any) => new Ticket(ticket))
          );
        }),
      );
  }

  add(ticketDto: any){
    return this.http.post(`${environment.apiUrl}api/v1/tickets`, {ticketDto})
      .pipe(
        map( (ticket: any) => {
          return new Ticket(ticket)
        })
      )
  }

  modify(ticketDto: any, ticketId: string){
    return this.http.put(`${environment.apiUrl}api/v1/tickets/${ticketId}`, ticketDto)
      .pipe(
        map( (ticket: any) => {
          return new Ticket(ticket)
        })
      )
  }

  delete(ticketId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/tickets/${ticketId}`)
  }
}
