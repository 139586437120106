import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Platform } from '../models/platform.model';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private http: HttpClient) {}

  all(){
    return this.http.get(`${environment.apiUrl}api/v1/platforms`)
      .pipe(
        map( (platforms: any) => platforms.map( (platform:any) => new Platform(platform) ))
      );
  }

  one(platformId: string){
    return this.http.get(`${environment.apiUrl}api/v1/platforms/${platformId}`)
      .pipe(
        map( (platform: any) => new Platform(platform))
      );
  }

  create(body: any){
    return this.http.post(`${environment.apiUrl}api/v1/platforms`, body).pipe(
      map( (platform: any) => { 
        return new Platform(platform)
      })
    )
  }

  modify(platformId: string, body: any){
    return this.http.put(`${environment.apiUrl}api/v1/platforms/${platformId}`, body).pipe(
      map( (platform: any) => { 
        return new Platform(platform)
      })
    )
  }

  delete(platformId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/platforms/${platformId}`)
  }

  activate(platformId: string){
    return this.http.post(`${environment.apiUrl}api/v1/platforms/${platformId}/enabled`, {})
  }
}
