import { createAction, props } from '@ngrx/store';
import { Ticket } from 'src/app/models/ticket.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllTicketsAction = createAction(
    '[Tickets] Get all tickets - Loading...',
    props<{currentPage: number, limit: number, status: string}>()
);

export const fetchAllTicketsActionSuccess = createAction(
    '[Tickets]  Get all tickets - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllTicketsActionFailed = createAction(
    '[Tickets]  Get all tickets - Failed',
    props<{errors: ErrorApi}>()
);

export const addTicketsAction = createAction(
    '[Tickets]  Add ticket - Adding...',
    props<{name: string}>()
);

export const addTicketsActionSuccess = createAction(
    '[Tickets]  Add ticket - Success',
);

export const addTicketsActionFailed = createAction(
    '[Tickets]  Add ticket - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyTicketsAction = createAction(
    '[Tickets]  Modify ticket - Modifying...',
    props<{data: any, ticketId: string, status: string}>()
);

export const modifyTicketsActionSuccess = createAction(
    '[Tickets]  Modify ticket - Success',
    props<{status: string}>()
);

export const modifyTicketsActionFailed = createAction(
    '[Tickets]  Modify ticket - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteTicketsAction = createAction(
    '[Tickets]  Delete ticket - Deleting...',
    props<{ticketId: string, status: string}>()
);

export const deleteTicketsActionSuccess = createAction(
    '[Tickets]  Delete ticket - Success',
    props<{status: string}>()
);

export const deleteTicketsActionFailed = createAction(
    '[Tickets]  Delete ticket - Failed',
    props<{errors: ErrorApi}>()
);

export const openTicketDialog = createAction(
    '[Tickets]  Open Ticket Dialog',
);

export const closeTicketDialog = createAction(
    '[Tickets]  Close Ticket Dialog',
);
