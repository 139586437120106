export class DataPaginated {
    public docs: any[] = [];
    public hasNextPage: boolean;
    public hasPrevPage: boolean;
    public limit: number;
    public nextPage: number | null;
    public offset: number;
    public page: number;
    public pagingCounter: number;
    public prevPage: number | null;
    public totalDocs: number;
    public totalPages: number;
   
    constructor(data: any, docs: any[]){
       this.hasPrevPage = data.hasPrevPage;
       this.hasNextPage = data.hasNextPage;
       this.hasPrevPage = data.hasPrevPage;
       this.limit = data.limit;
       this.nextPage = data.nextPage;
       this.offset = data.offset;
       this.page = data.page;
       this.pagingCounter = data.pagingCounter;
       this.prevPage = data.prevPage;
       this.totalDocs = data.totalDocs;
       this.totalPages = data.totalPages;
       this.docs = docs;
    }

}