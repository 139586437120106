import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import {
  getPermissionSuccessAction,
  getTokenAction,
  getTokenActionFailed,
  getTokenActionSuccess,
  getUserAction,
  getUserFailAction,
  getUserSuccessAction,
  loginAction,
  loginActionFailed,
  loginActionSuccess,
  logoutAction,
  resetPasswordAction,
  resetPasswordActionFailed,
  resetPasswordActionSuccess
} from '../actions/auth.actions';
import {UserPermission} from '../../models/user-permission.model';

export interface StateAuth{
    isAuthenticated: boolean | null,
    user: User | null,
    permissions: UserPermission[] | [],
    loading: boolean,
    accessToken: string | null,
    expiresIn: number | null,
    errors: ErrorApi | null
}

export const initialState:StateAuth  = {
    isAuthenticated: null,
    user: null,
    permissions: [],
    loading: false,
    accessToken: null,
    expiresIn: null,
    errors: null
};

const _authReducer = createReducer(
  initialState,
  on(getUserAction, (state) => ({...state, loading: true})),
  on(getUserSuccessAction, (state, payload) => { return  ({...state, user: new User(payload), loading: false}) }),
  on(getUserFailAction, (state) => ({...state, isAuthenticated: false, loading: false})),
  on(getPermissionSuccessAction, (state, payload) => ({...state, permissions: payload.permissions})),
  on(loginAction, (state) => ({...state, loading: true})),
  on(loginActionSuccess, (state, payload) => (
    {
      ...state,
      loading: false,
      isAuthenticated: true,
      error: null,
      accessToken: payload.accessToken,
      expiresIn: payload.expiresIn,
    })
  ),
  on(loginActionFailed, (state, payload) => ({...state, isAuthenticated: false, loading: false, errors: payload.errors })),
  on(logoutAction, (state) => ({...state, isAuthenticated: false, accessToken: null, expiresIn: null, user: null})),
  on(getTokenAction, (state) => ({...state, loading: true, errors: null})),
  on(getTokenActionSuccess, (state) => ({...state, loading: false})),
  on(getTokenActionFailed, (state, payload) => ({...state, loading: false, errors: payload.errors})),
  on(resetPasswordAction, (state) => ({...state, loading: true, errors: null})),
  on(resetPasswordActionSuccess, (state) => ({...state, loading: false})),
  on(resetPasswordActionFailed, (state, payload) => ({...state, loading: false, errors: payload.errors})),
);

export function authReducer(state: any, action: any) {
  return _authReducer(state, action);
}
