import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-app';

  constructor(translate: TranslateService) {
    const  currentLanguage  =  translate.getBrowserLang();
    const language = currentLanguage === 'fr' || currentLanguage === 'en' ? currentLanguage : 'fr';
    translate.setDefaultLang(language);
    translate.use(language);
  }
}
