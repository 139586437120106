import { createReducer, on } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { acceptSkillUsersAction, acceptSkillUsersActionFailed, acceptSkillUsersActionSuccess, addSkillUsersAction, addSkillUsersActionSuccess, addSkillUsersActionFailed, addSkillsAction, addSkillsActionFailed, addSkillsActionSuccess, closeSkillDialog, deleteSkillsAction, deleteSkillsActionFailed, deleteSkillsActionSuccess, fetchAllSkillsAction, fetchAllSkillsActionFailed, fetchAllSkillsActionSuccess, fetchAllSkillUsersAction, fetchAllSkillUsersActionFailed, fetchAllSkillUsersActionSuccess, modifySkillsAction, modifySkillsActionFailed, modifySkillsActionSuccess, openSkillDialog, rejectSkillUsersAction, rejectSkillUsersActionFailed, rejectSkillUsersActionSuccess } from '../actions/skills.actions';

export interface StateSkills{
    dataPaginated: DataPaginated | null,
    skillUsersData: DataPaginated | null,
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    adding: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    showSkillDialog: boolean,
}
 
export const initialState:StateSkills  = {
    dataPaginated: null,
    skillUsersData: null,
    loading: null,
    errors: null,
    errorsDialog: null,
    adding: null,
    modifying: null,
    deleting: null,
    showSkillDialog: false,
};
  
const _skillsReducer = createReducer(
  initialState,
  on(fetchAllSkillsAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllSkillsActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllSkillsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addSkillsAction, (state) =>  ({...state, adding: true })),
  on(addSkillsActionSuccess, (state) =>  ({...state, showSkillDialog: false, adding: false})),
  on(addSkillsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(modifySkillsAction, (state) =>  ({...state, modifying: true })),
  on(modifySkillsActionSuccess, (state) =>  ({...state, showSkillDialog: false, modifying: false})),
  on(modifySkillsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteSkillsAction, (state) =>  ({...state, deleting: true })),
  on(deleteSkillsActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteSkillsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(fetchAllSkillUsersAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllSkillUsersActionSuccess, (state, payload) =>  ({...state, skillUsersData: payload.dataPaginated, loading: false})),
  on(fetchAllSkillUsersActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addSkillUsersAction, (state) =>  ({...state, adding: true })),
  on(addSkillUsersActionSuccess, (state) =>  ({...state, adding: false})),
  on(addSkillUsersActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(acceptSkillUsersAction, (state) =>  ({...state, adding: true })),
  on(acceptSkillUsersActionSuccess, (state) =>  ({...state, adding: false})),
  on(acceptSkillUsersActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(rejectSkillUsersAction, (state) =>  ({...state, deleting: true })),
  on(rejectSkillUsersActionSuccess, (state) =>  ({...state, deleting: false})),
  on(rejectSkillUsersActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(openSkillDialog, (state) => ({...state, showSkillDialog: true })),
  on(closeSkillDialog, (state) => ({...state, showSkillDialog: false, errorsDialog: null })),
);
 
export function skillReducer(state: any, action: any) {
  return _skillsReducer(state, action);
}