import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { IncidentTypeService } from 'src/app/services/incident-types.service';
import * as fromRoot from '../reducers';
import { addIncidentTypesAction, addIncidentTypesActionFailed, addIncidentTypesActionSuccess, deleteIncidentTypesAction, deleteIncidentTypesActionFailed, deleteIncidentTypesActionSuccess, fetchAllIncidentTypesAction, fetchAllIncidentTypesActionFailed, fetchAllIncidentTypesActionSuccess, modifyIncidentTypesAction, modifyIncidentTypesActionFailed, modifyIncidentTypesActionSuccess } from '../actions/incident-types.actions';

@Injectable()
export class IncidentTypesEffect {

  fetchAllIncidentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllIncidentTypesAction),
      exhaustMap(action => 
        this.incidentTypeService.all(action.currentPage, action.limit).pipe(
          map( (incidentTypesPaginated: DataPaginated) =>  {
              return fetchAllIncidentTypesActionSuccess({dataPaginated: incidentTypesPaginated})}) ,
          catchError( (error) => of(fetchAllIncidentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addIncidentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIncidentTypesAction),
      exhaustMap(action => 
        this.incidentTypeService.add(action.name).pipe(
          map( () =>  addIncidentTypesActionSuccess()) ,
          catchError( (error) => of(addIncidentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllIncidentTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIncidentTypesActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.incidentTypeService.all(1, state.incidentTypes.dataPaginated?.limit ? state.incidentTypes.dataPaginated?.limit : 1).pipe(
            map( (incidentTypesPaginated: DataPaginated) =>  {
              return fetchAllIncidentTypesActionSuccess({
                dataPaginated: incidentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllIncidentTypesActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  modifyIncidentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyIncidentTypesAction),
      exhaustMap(action => 
        this.incidentTypeService.modify(action.name, action.incidentTypeId).pipe(
          map( () =>  modifyIncidentTypesActionSuccess()) ,
          catchError( (error) => of(modifyIncidentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllIncidentTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyIncidentTypesActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.incidentTypeService.all(state.incidentTypes.dataPaginated?.page ? state.incidentTypes.dataPaginated.page : 1, state.incidentTypes.dataPaginated?.limit ? state.incidentTypes.dataPaginated.limit : 1).pipe(
            map( (incidentTypesPaginated: DataPaginated) =>  {
              return fetchAllIncidentTypesActionSuccess({
                dataPaginated: incidentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllIncidentTypesActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  deleteIncidentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIncidentTypesAction),
      exhaustMap(action => 
        this.incidentTypeService.delete(action.incidentTypeId).pipe(
          map( () =>  deleteIncidentTypesActionSuccess()) ,
          catchError( (error) => of(deleteIncidentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllIncidentTypesSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteIncidentTypesActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.incidentTypeService.all(state.incidentTypes.dataPaginated?.page ? ((state.incidentTypes.dataPaginated?.page == state.incidentTypes.dataPaginated?.totalPages) && (state.incidentTypes.dataPaginated?.docs.length == 1) ? state.incidentTypes.dataPaginated?.page - 1 : state.incidentTypes.dataPaginated?.page) : 1,
                              state.incidentTypes.dataPaginated?.limit ? state.incidentTypes.dataPaginated.limit : 1)
          .pipe(
            map( (incidentTypesPaginated: DataPaginated) =>  {
              return fetchAllIncidentTypesActionSuccess({
                dataPaginated: incidentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllIncidentTypesActionFailed({errors: extractErrors(error)})))
      )
      )
    )
);
 
  constructor(
    private actions$: Actions,
    private incidentTypeService: IncidentTypeService,
    private store$: Store<fromRoot.AppState>
  ) {}
}