import { createReducer, on } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { addTicketsAction, addTicketsActionFailed, addTicketsActionSuccess, closeTicketDialog, deleteTicketsAction, deleteTicketsActionFailed, deleteTicketsActionSuccess, fetchAllTicketsAction, fetchAllTicketsActionFailed, fetchAllTicketsActionSuccess, modifyTicketsAction, modifyTicketsActionFailed, modifyTicketsActionSuccess, openTicketDialog } from '../actions/tickets.actions';

export interface StateTickets{
    dataPaginated: DataPaginated | null,
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    adding: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    showTicketDialog: boolean,
}
 
export const initialState:StateTickets  = {
    dataPaginated: null,
    loading: null,
    errors: null,
    errorsDialog: null,
    adding: null,
    modifying: null,
    deleting: null,
    showTicketDialog: false,
};
  
const _ticketsReducer = createReducer(
  initialState,
  on(fetchAllTicketsAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllTicketsActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllTicketsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addTicketsAction, (state) =>  ({...state, adding: true })),
  on(addTicketsActionSuccess, (state) =>  ({...state, showTicketDialog: false, adding: false})),
  on(addTicketsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(modifyTicketsAction, (state) =>  ({...state, modifying: true })),
  on(modifyTicketsActionSuccess, (state) =>  ({...state, showTicketDialog: false, modifying: false})),
  on(modifyTicketsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteTicketsAction, (state) =>  ({...state, deleting: true })),
  on(deleteTicketsActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteTicketsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(openTicketDialog, (state) => ({...state, showTicketDialog: true })),
  on(closeTicketDialog, (state) => ({...state, showTicketDialog: false, errorsDialog: null })),
);
 
export function ticketReducer(state: any, action: any) {
  return _ticketsReducer(state, action);
}