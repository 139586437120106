import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';

export class IncidentType implements Deserializable {
    public _id: string;
    public name: string;
    public platform: Platform | string;
   
    constructor(data: any){
        this._id = data._id;
        this.name = data.name;
        this.platform = data.platform;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}