import { createAction, props } from '@ngrx/store';
import { MeasurementsStatus, User } from 'src/app/models/user.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllSuperAdminsAction = createAction(
    '[Users] Get all superadmins - Loading...',
    props<{currentPage: number, limit: number, search?: string}>()
);

export const fetchAllSuperAdminsActionSuccess = createAction(
    '[Users]  Get all superadmins - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllSuperAdminsActionFailed = createAction(
    '[Users] Get all superadmins - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllAdminsAction = createAction(
    '[Users] Get all admins - Loading...',
    props<{currentPage: number, limit: number, search?: string}>()
);

export const fetchAllAdminsActionSuccess = createAction(
    '[Users] Get all admins - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllAdminsActionFailed = createAction(
    '[Users] Get all admins - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllUsersAction = createAction(
    '[Users] Get all users - Loading...',
    props<{currentPage: number, limit: number, search?: string}>()
);

export const fetchAllUsersActionSuccess = createAction(
    '[Users] Get all users - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllUsersActionFailed = createAction(
    '[Users] Get all users - Failed',
    props<{errors: ErrorApi}>()
);

export const showDialogUser = createAction(
    '[Users] Show dialog'
);

export const closeDialogUser = createAction(
    '[Users] Close dialog'
);

export const showDialogAddUserSkill = createAction(
    '[Users] Show dialog add skill'
);

export const closeDialogAddUserSkill = createAction(
    '[Users] Close dialog add skill'
);

export const addUserAction = createAction(
    '[Users]  Create users',
    props<{body: any}>()
);

export const addUserActionSuccess = createAction(
    '[Users]  Create users - Success',
    props<{user: User}>()
);

export const addUsersActionFailed = createAction(
    '[Users]  Create users - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyUserAction = createAction(
    '[Users]  Modify users',
    props<{user: User, body: any}>()
);

export const modifyUserActionSuccess = createAction(
    '[Users]  Modify users - Success',
    props<{user: User}>()
);

export const modifyUsersActionFailed = createAction(
    '[Users]  Modify users - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyUserMeasurementsAction = createAction(
  '[Users]  Modify users measurements',
  props<{user: User, body: any}>()
);

export const deleteUserAction = createAction(
    '[Users]  Delete user - Deleting...',
    props<{user: User}>()
);

export const deleteUserActionSuccess = createAction(
    '[[Users]  Delete user - Success',
    props<{user: User}>()
);

export const deleteUserActionFailed = createAction(
    '[Users]  Delete user - Failed',
    props<{errors: ErrorApi}>()
);
