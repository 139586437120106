import { Deserializable } from './interfaces/deserializable.interface';

export class Platform implements Deserializable {
    public _id: string;
    public name: string;
    public description: string;
    public primaryColor: string;
    public registrationIsEnabled: string;
    public logoUrl: string;
    public backgroundLoginUrl: string;
    public enabled: boolean;
    public darkMode: boolean;
    public technicalName: string;
    public registrationMessage: string;
    public cgv: string;
    public cgu: string;

    constructor(data: any){
        this._id = data._id;
        this.name = data.name;
        this.description = data.description;
        this.primaryColor = data.primaryColor;
        this.registrationIsEnabled = data.registrationIsEnabled;
        this.logoUrl = data.logoUrl;
        this.backgroundLoginUrl = data.backgroundLoginUrl;
        this.enabled = data.enabled;
        this.darkMode = data.darkMode
        this.technicalName = data.technicalName;
        this.registrationMessage = data.registrationMessage;
        this.cgv = data.cgv;
        this.cgu = data.cgu;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}

export interface IFormPlatform{
    name: string,
    enabled: boolean,
    description: string,
    technicalName: string,
    primaryColor: string,
    darkMode: boolean,
    logo: any,
    backgroundLogin: any,
    registrationIsEnabled: boolean,
    registrationMessage: string
}
