import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';

export class Job implements Deserializable {
    public _id: string;
    public jobname: string;
    public isManager: boolean;
    public platform: Platform | string;
   
    constructor(data: any){
        this._id = data._id;
        this.jobname = data.jobname;
        this.isManager = data.isManager;
        this.platform = data.platforms;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}