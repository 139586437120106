import { Deserializable } from "../interfaces/deserializable.interface";
import { User } from "../user.model";
import { MissionJob } from "./missionJob.model";

export enum ApplicationStatus{
    applied="applied",
    in_treatment="in_treatment",
    rejected="rejected",
    contract_to_sign="contract_to_sign",
    ready="ready",
    completed="completed",
}

export class MissionApplied implements Deserializable {
    public _id: string;
    public missionJob: any;
    public userId: User;
    public status: string;
    public note: any;
    public createdAt: Date;
    public updatedAt: Date;

    constructor(data: any){
        console.log(data)
        this._id = data._id;
        this.missionJob = data.missionJob;
        this.userId = data.userId;
        this.status = data.status;
        this.note = data.note;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}