import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {Mission} from '../models/mission/mission.model';
import {MissionJob} from 'src/app/models/mission/missionJob.model';
import {DataPaginated} from '../models/utils/data-paginated.model';
import {MissionApplied} from '../models/mission/missionApplied.model';
import {Team} from '../models/mission/team.model';
import {TeamMember} from '../models/mission/teamMember.model';
import {Incident} from '../models/mission/incident.model';
import {Report} from '../models/mission/report.model';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MissionService {

  constructor(private http: HttpClient) {
  }

  all(currentPage: number, limit?: number, isHistory?: string, missionStatus?: string, start?: string, end?: string ) {
    let params = new HttpParams();
    params = params.append('page', !currentPage ? '1' : currentPage.toString());
    params = params.append('limit', !limit ? '10' : limit.toString());
    if (isHistory) {
      params = params.append('isHistory', isHistory);
    }
    if (missionStatus) {
      params = params.append('mission_status', missionStatus);
    }
    if (start) {
      params = params.append('start_at', start);
    }
    if (end) {
      params = params.append('end_at', end);
    }

    return this.http.get(`${environment.apiUrl}api/v1/missions`, {params: params})
      .pipe(
        map((missions_paginated: any) => {
          const {docs, ...others} = missions_paginated;
          return new DataPaginated(
            others,
            docs.map((mission: any) => new Mission(mission))
          );
        }),
      );
  }

  get(id: string) {
    return this.http.get(`${environment.apiUrl}api/v1/missions/${id}`)
      .pipe(
        map((mission) => new Mission(mission))
      );
  }

  allJob(missionId: string) {

    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/jobs`)
      .pipe(
        map((missionJobs: any) => {
            return missionJobs.map((missionJob: any) => new MissionJob(missionJob));
          }
        ),
      );
  }

  allApplied(currentPage: number, limit: number, status: string, missionId: string, missionJobId: string) {
    let params = new HttpParams();
    params = params.append('page', !currentPage ? '1' : currentPage.toString());
    params = params.append('limit', !limit ? '10' : limit.toString());
    params = params.append('status', status);
    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}/applications`, {params: params})
      .pipe(
        map((missionsApplieds: any) => {
          const {docs, ...others} = missionsApplieds;
          return new DataPaginated(
            others,
            docs.map((missionApplied: any) => new MissionApplied(missionApplied))
          );
        }),
      );
  }

  allPrepays(currentPage: number, limit: number, startDate?: Date, endDate?: Date) {
    let params = new HttpParams();
    params = params.append('page', !currentPage ? '1' : currentPage.toString());
    params = params.append('limit', !limit ? '10' : limit.toString());
    params = params.append('start_at', !startDate ? '' : startDate.toString());
    params = params.append('end_at', !endDate ? '' : endDate.toString());
    return this.http.get(`${environment.apiUrl}api/v1/missions/prepays`, {params})
      .pipe(
        map((missionsApplieds: any) => {
          const {docs, ...others} = missionsApplieds;
          return new DataPaginated(
            others,
            docs.map((prepay: any) => {
              return {missionApplied: new MissionApplied(prepay)};
            })
          );
        })
      );
  }

  allAppliedByUser(currentPage: number, limit: number, userId: string) {
    let params = new HttpParams();
    params = params.append('page', !currentPage ? '1' : currentPage.toString());
    params = params.append('limit', !limit ? '10' : limit.toString());
    params = params.append('user', userId);
    return this.http.get(`${environment.apiUrl}api/v1/missions/applications`, {params: params})
      .pipe(
        map((missionsApplieds: any) => {
          const {docs, ...others} = missionsApplieds;
          return new DataPaginated(
            others,
            docs.map((missionApplied: any) => new MissionApplied(missionApplied))
          );
        }),
      );
  }

  allTeams(missionId: string) {

    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/teams`)
      .pipe(
        map((teams: any) => {
            return teams.map((team: any) => new Team(team));
          }
        ),
      );
  }

  allTeamMembers(missionId: string) {

    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/members`)
      .pipe(
        map((teamMembers: any) => {
            return teamMembers.map((teamMember: any) => new TeamMember(teamMember));
          }
        ),
      );
  }

  allIncidents(missionId: string) {

    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/incidents`)
      .pipe(
        map((incidents: any) => {
            return incidents.map((incident: any) => new Incident(incident));
          }
        ),
      );
  }

  allReports(missionId: string) {

    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/reports`)
      .pipe(
        map((reports: any) => {
            return reports.map((report: any) => new Report(report));
          }
        ),
      );
  }

  exportPdf(missionId: string) {
    return this.http.get(`${environment.apiUrl}api/v1/missions/${missionId}/export`, {responseType: 'blob'})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  accept(missionId: string, missionJobId: string, appId: string) {
    return this.http.post(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}/applications/${appId}`, null)
      .pipe(
        map((missionApplied: any) => {
          return new MissionApplied(missionApplied);
        })
      );
  }

  notifyUsersApplied(missionId: string, body: any) {
    return this.http.post(`${environment.apiUrl}api/v1/missions/${missionId}/notify-users`, body);
  }

  reject(missionId: string, missionJobId: string, appId: string) {
    return this.http.delete(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}/applications/${appId}`);
  }

  note(missionId: string, missionJobId: string, appId: string, noteDto: any) {
    return this.http.patch(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}/applications/${appId}`, noteDto)
      .pipe(
        map((missionApplied: any) => {
          return new MissionApplied(missionApplied);
        })
      );
  }

  add(missionDto: any) {
    const addressDto = {
      address: missionDto.address,
      zipCode: missionDto.cp,
      city: missionDto.city,
      additionnalAddress: missionDto.compl_address
    };
    const {address, compl_address, cp, city, start_date, end_date, ...others} = missionDto;
    let startDate = new Date(start_date);
    let endDate = new Date(end_date);

    const dto = {address: addressDto, startDate: startDate, endDate: endDate, ...others};
    return this.http.post(`${environment.apiUrl}api/v1/missions`, dto)
      .pipe(
        map((mission: any) => {
          return new Mission(mission);
        })
      );
  }

  addJob(missionId: string, missionJobDto: any) {
    return this.http.post(`${environment.apiUrl}api/v1/missions/${missionId}/jobs`, missionJobDto)
      .pipe(
        map((missionJob: any) => {
          return new MissionJob(missionJob);
        })
      );
  }

  modify(missionId: string, missionDto: any) {
    const addressDto = {
      address: missionDto.address,
      zipCode: missionDto.cp,
      city: missionDto.city,
      additionnalAddress: missionDto.compl_address
    };
    const {address, compl_address, cp, city, start_date, end_date, lat, long, ...others} = missionDto;
    let startDate = new Date(start_date);
    let endDate = new Date(end_date);

    const dto = {address: addressDto, startDate: startDate, endDate: endDate, lat: lat, long: long, ...others};
    return this.http.put(`${environment.apiUrl}api/v1/missions/${missionId}`, dto)
      .pipe(
        map((mission: any) => {
          return new Mission(mission);
        })
      );
  }

  modifyJob(missionId: string, missionJobId: string, missionJobDto: any) {
    return this.http.put(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}`, missionJobDto)
      .pipe(
        map((missionJob: any) => {
          return new MissionJob(missionJob);
        })
      );
  }

  changeTeamMember(missionId: string, changeTeamMemberDto: any) {
    return this.http.put(`${environment.apiUrl}api/v1/missions/${missionId}/teams/change`, changeTeamMemberDto);
  }

  delete(missionId: string) {
    return this.http.delete(`${environment.apiUrl}api/v1/missions/${missionId}`);
  }

  deleteJob(missionId: string, missionJobId: string) {
    return this.http.delete(`${environment.apiUrl}api/v1/missions/${missionId}/jobs/${missionJobId}`);
  }

  getAddress(address: string) {
    let params = new HttpParams();
    params = params.append('q', address);
    return this.http.get(`${environment.apiUrl}api/v1/missions/location`, {params: params})
      .pipe(
        map((res: any) => {
          return new Array(res);
        })
      );
  }

}
