import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { DocumentType } from 'src/app/models/document-type.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import * as fromRoot from '../reducers';
import { acceptDocumentTypeUsersAction, acceptDocumentTypeUsersActionFailed, acceptDocumentTypeUsersActionSuccess, addDocumentTypesAction, addDocumentTypesActionFailed, addDocumentTypesActionSuccess, deleteDocumentTypesAction, deleteDocumentTypesActionFailed, deleteDocumentTypesActionSuccess, fetchAllDocumentTypesAction, fetchAllDocumentTypesActionFailed, fetchAllDocumentTypesActionSuccess, fetchAllDocumentTypeUsersAction, fetchAllDocumentTypeUsersActionFailed, fetchAllDocumentTypeUsersActionSuccess, modifyDocumentTypesAction, modifyDocumentTypesActionFailed, modifyDocumentTypesActionSuccess, rejectDocumentTypeUsersAction, rejectDocumentTypeUsersActionFailed, rejectDocumentTypeUsersActionSuccess } from '../actions/document-types.actions';
import { DocumentTypeService } from 'src/app/services/document-types.service';

@Injectable()
export class DocumentTypesEffect { 

  fetchAllDocumentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllDocumentTypesAction),
      exhaustMap(action => 
        this.documentTypeService.all(action.currentPage, action.limit).pipe(
          map( (documentTypesPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypesActionSuccess({dataPaginated: documentTypesPaginated})}) ,
          catchError( (error) => of(fetchAllDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllDocumentTypeUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllDocumentTypeUsersAction),
      exhaustMap(action => 
        this.documentTypeService.allDocumentTypeUser(action.currentPage, action.limit, action.status, action.documentType, action.search).pipe(
          map( (documentTypeUsersPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypeUsersActionSuccess({dataPaginated: documentTypeUsersPaginated})}),
          catchError( (error) => of(fetchAllDocumentTypeUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addDocumentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDocumentTypesAction),
      exhaustMap(action => 
        this.documentTypeService.add(action.name, action.require, action.expiryDateRequire).pipe(
          map( () =>  addDocumentTypesActionSuccess()) ,
          catchError( (error) => of(addDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllDocumentTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDocumentTypesActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.documentTypeService.all(1, state.documentTypes.dataPaginated?.limit ? state.documentTypes.dataPaginated?.limit : 1).pipe(
            map( (documentTypesPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypesActionSuccess({
                dataPaginated: documentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllDocumentTypesActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  acceptDocumentTypeUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptDocumentTypeUsersAction),
      exhaustMap(action => 
        this.documentTypeService.accept(action.documentTypeUserId).pipe(
          map( () =>  acceptDocumentTypeUsersActionSuccess()) ,
          catchError( (error) => of(acceptDocumentTypeUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  acceptAllDocumentTypeUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptDocumentTypeUsersActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.documentTypeService.allDocumentTypeUser(state.documentTypes.dataPaginated?.page ? state.documentTypes.dataPaginated?.page : 1, state.documentTypes.dataPaginated?.limit ? state.documentTypes.dataPaginated?.limit : 1).pipe(
            map( (documentTypeUsersPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypeUsersActionSuccess({
                dataPaginated: documentTypeUsersPaginated
              })
            }),
            catchError( (error) => of(fetchAllDocumentTypeUsersActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  modifyDocumentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyDocumentTypesAction),
      exhaustMap(action => 
        this.documentTypeService.modify(action.name, action.require, action.expiryDateRequire, action.documentTypeId).pipe(
          map( () =>  modifyDocumentTypesActionSuccess()) ,
          catchError( (error) => of(modifyDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllDocumentTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyDocumentTypesActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.documentTypeService.all(state.documentTypes.dataPaginated?.page ? state.documentTypes.dataPaginated.page : 1, state.documentTypes.dataPaginated?.limit ? state.documentTypes.dataPaginated.limit : 1).pipe(
            map( (documentTypesPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypesActionSuccess({
                dataPaginated: documentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  deleteDocumentTypesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDocumentTypesAction),
      exhaustMap(action => 
        this.documentTypeService.delete(action.documentTypeId).pipe(
          map( () =>  deleteDocumentTypesActionSuccess()) ,
          catchError( (error) => of(deleteDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllDocumentTypesSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteDocumentTypesActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.documentTypeService.all(state.documentTypes.dataPaginated?.page ? ((state.documentTypes.dataPaginated?.page == state.documentTypes.dataPaginated?.totalPages) && (state.documentTypes.dataPaginated?.docs.length == 1) ? state.documentTypes.dataPaginated?.page - 1 : state.documentTypes.dataPaginated?.page) : 1,
                              state.documentTypes.dataPaginated?.limit ? state.documentTypes.dataPaginated.limit : 1)
          .pipe(
            map( (documentTypesPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypesActionSuccess({
                dataPaginated: documentTypesPaginated
              })
            }),
            catchError( (error) => of(fetchAllDocumentTypesActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  rejectDocumentTypeUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectDocumentTypeUsersAction),
      exhaustMap(action => 
        this.documentTypeService.reject(action.documentTypeUserId).pipe(
          map( () =>  rejectDocumentTypeUsersActionSuccess()) ,
          catchError( (error) => of(rejectDocumentTypeUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  rejectAllDocumentTypeUsersSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(rejectDocumentTypeUsersActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.documentTypeService.allDocumentTypeUser(state.documentTypes.dataPaginated?.page ? ((state.documentTypes.dataPaginated?.page == state.documentTypes.dataPaginated?.totalPages) && (state.documentTypes.dataPaginated?.docs.length == 1) ? state.documentTypes.dataPaginated?.page - 1 : state.documentTypes.dataPaginated?.page) : 1,
                              state.documentTypes.dataPaginated?.limit ? state.documentTypes.dataPaginated.limit : 1)
          .pipe(
            map( (documentTypeUsersPaginated: DataPaginated) =>  {
              return fetchAllDocumentTypeUsersActionSuccess({
                dataPaginated: documentTypeUsersPaginated
              })
            }),
            catchError( (error) => of(fetchAllDocumentTypeUsersActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );
 
  constructor(
    private actions$: Actions,
    private documentTypeService: DocumentTypeService,
    private store$: Store<fromRoot.AppState>
  ) {}
}