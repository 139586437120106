import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {DataPaginated} from '../../models/utils/data-paginated.model';
import {of} from 'rxjs';
import {extractErrors} from '../../helpers/errorsHelper';
import {MissionService} from '../../services/missions.service';
import {fetchAllPrepaysAction, fetchAllPrepaysActionFailed, fetchAllPrepaysActionSuccess} from '../actions/prepay.action';

@Injectable()
export class PrepaysEffect {

  fetchAllPrepaysAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllPrepaysAction),
      exhaustMap(action =>
        this.missionService.allPrepays(action.currentPage, action.limit, action.startDate, action.endDate).pipe(
          map((prepaysPaginated: DataPaginated) => {
            return fetchAllPrepaysActionSuccess({dataPaginated: prepaysPaginated});
          }),
          catchError((error) => of(fetchAllPrepaysActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private missionService: MissionService,
  ) {
  }
}
