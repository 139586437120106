import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
  })
  export class TokenInterceptor implements HttpInterceptor {
  
    constructor(
      private authService: AuthService, 
      private translate: TranslateService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler){
      const currentLanguage = this.translate.getBrowserLang();
      const language = currentLanguage === 'fr' || currentLanguage === 'en' ? currentLanguage : 'fr';

      request = request.clone({
        setHeaders: {
          Lang: language,
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      });
   
      return next.handle(request);
    }
  }
  