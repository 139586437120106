import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {map, tap} from 'rxjs/operators';
import {UserPermission} from '../models/user-permission.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  login(body: any){
    return this.http.post(`${environment.apiUrl}api/v1/auth/login`, body)
      .pipe(
        map( (res: any) =>  this.storeToken(res.accessToken, res.expiresIn) )
      );
  }

  postUserLogin(body: any) {
    return this.http.post(`${environment.apiUrl}api/v1/auth/login`, body)
    .pipe(
        map( (res: any) =>  this.storeToken(res.accessToken, res.expiresIn)
      )
   );
  }

  resetToken(email: string){
    return this.http.post(`${environment.apiUrl}api/v1/auth/demand-password`, {email: email})
  }

  resetPassword(email: string, token: string | null, password: string, repeat_password: string ){
    return this.http.post(`${environment.apiUrl}api/v1/auth/reset-password`, {email: email, token: token, password: password, repeat_password: repeat_password})
  }

  storeToken(token: string, expiresIn: number){
    localStorage.setItem('token', token)
    localStorage.setItem('expiresIn', expiresIn.toString())
  }

  validateAccount(token: string, password: string){
    return this.http.post(`${environment.apiUrl}api/v1/auth/validate-account`, {tokenPassword: token, password: password})
  }

  getPermissions(): Observable<UserPermission[]> {
    return this.http.get(`${environment.apiUrl}api/v1/auth/me/permissions`)
      .pipe(
        map( (res: any) => res.map((userPermission: UserPermission) => new UserPermission(userPermission)))
      );
  }

  getToken(){
    return localStorage.getItem('token');
  }

  getUser(){
    return this.http.get(environment.apiUrl + 'api/v1/auth/me');
  }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
  }
}
