import { Deserializable } from '../interfaces/deserializable.interface';
import { Platform } from '../platform.model';

export enum MissionStatus{
  all = "all",
  pending = "pending",
  recruiting = "recruiting",
  waiting_contracts = "waiting_contracts",
  end_of_recruitment = "end_of_recruitment",
  ready = "ready",
  completed = "completed"
}

export class Mission implements Deserializable {
    public _id: string;
    public name: string;
    public platform: Platform | string;
    public address: any;
    public location: any;
    public progress: number;
    public status: string;
    public startDate: Date;
    public endDate: Date;
    public enable: string;
    public nbr: number;

    constructor(data: any){
        this._id = data._id;
        this.name = data.name;
        this.platform = data.platform;
        this.address = data.address;
        this.location = data.location;
        this.progress = data.progress;
        this.status = data.status;
        this.startDate = new Date(data.startDate);
        this.endDate = new Date(data.endDate);
        this.enable = data.enable;
        this.nbr = 1;

    }

    getAdress(){
        return this.address.address + " , " + this.address.zipCode + " , " + this.address.city
    }

    setNumber(number: number){
        this.nbr = number
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}
