import { Deserializable } from "../interfaces/deserializable.interface";
import { Team } from "./team.model";

export class Report implements Deserializable {
    _id: string
    opinionGeneralMission: string;
    opinionOfficers: string;
    opinionClients: string;
    difficulties: string;
    missionId: string;
    teamId: Team;

    constructor(data: any){
        this._id = data._id;
        this.opinionGeneralMission = data.opinionGeneralMission;
        this.opinionOfficers = data.opinionOfficers;
        this.opinionClients = data.opinionClients;
        this.difficulties = data.difficulties;
        this.missionId = data.missionId;
        this.teamId = data.teamId;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}