import { IncidentType } from "../incident-type.model";
import { Deserializable } from "../interfaces/deserializable.interface";
import { User } from "../user.model";
import { Team } from "./team.model";

export class Incident implements Deserializable {
    public _id: string
    public incidentType: IncidentType;
    public users: User[];
    public photo: string
    public order: number;
    public date: Date;
    public hour: string;
    public comments: string;
    public missionId: string;
    public teamId: Team;
    public photoUrl?: string;

    constructor(data: any){
        this._id = data._id;
        this.incidentType = data.incidentType;
        this.users = data.users.map( (user: any) => new User(user));
        this.photo = data.photo
        this.order = data.order;
        this.date = new Date(data.date);
        this.hour = data.hour;
        this.comments = data.comments;
        this.missionId = data.missionId;
        this.teamId = new Team(data.teamId);
        this.photoUrl = data.photoUrl
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}