import { MatDialogConfig } from '@angular/material/dialog';
import { createReducer, on } from '@ngrx/store';
import { USE_DEFAULT_LANG } from '@ngx-translate/core';
import { Mission } from 'src/app/models/mission/mission.model';
import { MissionApplied } from 'src/app/models/mission/missionApplied.model';
import { MissionJob } from 'src/app/models/mission/missionJob.model';
import { Team } from 'src/app/models/mission/team.model';
import { TeamMember } from 'src/app/models/mission/teamMember.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { addMissionsAction, addMissionsActionFailed, addMissionsActionSuccess, addMissionsJobAction, addMissionsJobActionFailed, addMissionsJobActionSuccess, closeMissionJobDialog, deleteMissionsAction, deleteMissionsActionFailed, deleteMissionsActionSuccess, deleteMissionsJobAction, deleteMissionsJobActionFailed, deleteMissionsJobActionSuccess, editMissionsAction, editNumberAction, fetchAllMissionsAction, fetchAllMissionsActionFailed, fetchAllMissionsActionSuccess, fetchAllMissionsAppliedAction, fetchAllMissionsAppliedActionFailed, fetchAllMissionsAppliedActionSuccess, fetchAllMissionsJobAction, fetchAllMissionsJobActionFailed, fetchAllMissionsJobActionSuccess, fetchAllTeamAction, fetchAllTeamActionSuccess, fetchAllTeamActionFailed, modifyMissionsAction, modifyMissionsActionFailed, modifyMissionsActionSuccess, modifyMissionsJobAction, modifyMissionsJobActionFailed, modifyMissionsJobActionSuccess, newMissionsAction, numberMissionAppliedDeclined, openMissionJobDialog, fetchAllTeamMemberAction, fetchAllTeamMemberActionSuccess, fetchAllTeamMemberActionFailed, openNotesDialog, closeNotesDialog } from '../actions/missions.actions';
import { closeConfirmDialog } from '../actions/sharing.actions';

export interface StateMissions{
    dataPaginated: DataPaginated | null,
    jobdata: MissionJob[] | null,
    missionApplied: MissionApplied[] | null,
    teams: Team[],
    teamMembers: TeamMember[],
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    edited_mission: Mission | null,
    adding: boolean | null,
    addingJob: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    number: number,
    change: boolean,
    showMissionJobDialog: boolean,
    showNotesDialog: boolean,
    dialogConfig: MatDialogConfig,
    existDeclined: boolean
}
 
export const initialState:StateMissions  = {
    dataPaginated: null,
    jobdata: null,
    missionApplied: null,
    teams: [],
    teamMembers: [],
    loading: null,
    errors: null,
    errorsDialog: null,
    edited_mission: null,
    adding: null,
    addingJob: null,
    modifying: null,
    deleting: null,
    number: 1,
    change: false,
    showMissionJobDialog: false,
    showNotesDialog: false,
    dialogConfig: new MatDialogConfig(),
    existDeclined: false
};
  
const _missionsReducer = createReducer(
  initialState,
  on(fetchAllMissionsAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllMissionsActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllMissionsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addMissionsAction, (state) =>  ({...state, adding: true })),
  on(addMissionsActionSuccess, (state, payload) =>  ({...state, adding: false, edited_mission: payload.edited_mission, change: true})),
  on(addMissionsActionFailed, (state, payload) => ({...state, errors: payload.errors, adding: false })),
  on(modifyMissionsAction, (state) =>  ({...state, modifying: true })),
  on(modifyMissionsActionSuccess, (state) =>  ({...state, modifying: false, change: true})),
  on(modifyMissionsActionFailed, (state, payload) => ({...state, errors: payload.errors, modifying: false })),
  on(deleteMissionsAction, (state) =>  ({...state, deleting: true })),
  on(deleteMissionsActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteMissionsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(closeConfirmDialog, (state) => ({...state, errorsDialog: null })),
  on(editMissionsAction, (state,payload) => ({...state, edited_mission: payload.edited_mission, number: payload.number, change: false})),
  on(newMissionsAction, (state) => ({...state, edited_mission: null, number: 1, jobdata: null, teams: [], teamMembers: []})),
  on(editNumberAction, (state,payload) => ({...state, number: payload.number, change: false})),

  on(fetchAllMissionsJobAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllMissionsJobActionSuccess, (state, payload) =>  ({...state, jobdata: payload.data, loading: false})),
  on(fetchAllMissionsJobActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addMissionsJobAction, (state) =>  ({...state, addingJob: true })),
  on(addMissionsJobActionSuccess, (state) =>  ({...state, addingJob: false})),
  on(addMissionsJobActionFailed, (state, payload) => ({...state, errors: payload.errors, addingJob: false })),
  on(modifyMissionsJobAction, (state) =>  ({...state, modifying: true })),
  on(modifyMissionsJobActionSuccess, (state) =>  ({...state, showMissionJobDialog: false, modifying: false})),
  on(modifyMissionsJobActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteMissionsJobAction, (state) =>  ({...state, deleting: true })),
  on(deleteMissionsJobActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteMissionsJobActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),

  on(fetchAllMissionsAppliedAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllMissionsAppliedActionSuccess, (state, payload) =>  ({...state, missionApplied: payload.data, loading: false})),
  on(fetchAllMissionsAppliedActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),

  on(fetchAllTeamAction, (state) => ({...state, loading: true, errors :null})),
  on(fetchAllTeamActionSuccess, (state, payload) =>  ({...state, teams: payload.data, loading: false})),
  on(fetchAllTeamActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(fetchAllTeamMemberAction, (state) => ({...state, loading: true, errors :null})),
  on(fetchAllTeamMemberActionSuccess, (state, payload) =>  ({...state, teamMembers: payload.data, loading: false})),
  on(fetchAllTeamMemberActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),

  on(openMissionJobDialog, (state, payload) => ({...state, showMissionJobDialog: true, dialogConfig: payload.dialogConfig })),
  on(closeMissionJobDialog, (state) => ({...state, showMissionJobDialog: false, errorsDialog: null })),
  on(openNotesDialog, (state, payload) => ({...state, showNotesDialog: true, dialogConfig: payload.dialogConfig })),
  on(closeNotesDialog, (state) => ({...state, showNotesDialog: false, errorsDialog: null })),

  on(numberMissionAppliedDeclined, (state, payload) =>  ({...state, existDeclined: payload.existDeclined})),
);
 
export function missionReducer(state: any, action: any) {
  return _missionsReducer(state, action);
}