import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';
import { User } from './user.model';

export enum Status {
    pending = "pending",
    done = "done"
  }

export enum Type {
    postulateMission = "postulateMission",
    noContact = "noContact",
    issueNotification = "issueNotification",
    other = "other"
  }

export class Ticket implements Deserializable {
    public _id: string;
    public type: string;
    public content: string;
    public response: string;
    public status: string;
    public user: User;
    public platform: Platform | string;

    constructor(data: any){
        this._id = data._id;
        this.type = data.type;
        this.content = data.content;
        this.response = data.response;
        this.status = data.status;
        this.user = data.user;
        this.platform = data.platform;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

  getLabelType() {
    switch (this.type) {
      case Type.postulateMission:
        return "Problème pour postuler à un événément";
      case Type.noContact:
        return "Vous n'avez pas été contacté";
      case Type.issueNotification:
        return "Ne reçoit pas de notification";
      case Type.other:
        return "Autre";
      default:
        return "";
    }
  }
}
