import { MatDialogConfig } from "@angular/material/dialog";
import { createAction, props } from "@ngrx/store";

export const openConfirmDialog = createAction(
    '[All]  Open Confirm Dialog',
);

export const openConfirmDialogOf = createAction(
    '[All]  Open Confirm Dialog',
    props<{dialogConfig: MatDialogConfig}>()
);

export const closeConfirmDialog = createAction(
    '[All]  Close Confirm Dialog',
);