import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Skill } from '../models/skill.model';
import { DataPaginated } from '../models/utils/data-paginated.model';
import { SkillUser } from '../models/skillUser.model';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http: HttpClient) {}

  all(currentPage: number, limit: number, startDate?: string){

    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if (startDate) params = params.append('startDate', startDate);
    return this.http.get(`${environment.apiUrl}api/v1/skills`, {params: params})
      .pipe(
        map( (skills_paginated: any) => {
          const {docs, ...others} = skills_paginated
          return new DataPaginated(
            others,
            docs.map( (skill:any) => new Skill(skill))
          );
        }),
      );
  }

  allSkillUser(currentPage: number, limit: number, status?: string, skillType?: string, search?: string, userId?: string, startDate?: string){

    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if(status){
      params = params.append('status', status);
    }
    if(skillType){
      params = params.append('skillType', skillType);
    }
    if(search){
      params = params.append('search', search);
    }
    if(userId){
      params = params.append('user', userId);
    }
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    return this.http.get(`${environment.apiUrl}api/v1/skills/skillUsers`, {params: params})
      .pipe(
        map( (skillUsers_paginated: any) => {
          const {docs, ...others} = skillUsers_paginated
          return new DataPaginated(
            others,
            docs.map( (skillUser:any) => new SkillUser(skillUser))
          );
        }),
      );
  }

  addSkillUser(skillUserId: string, userId: string, graduationDate: Date){
    let params = new HttpParams({
      fromObject : {
        'skillId': skillUserId,
        'userId': userId,
        'graduationDate': graduationDate.toString(),
        // 'fileBase64': 'test'
      }
    })
    return this.http.post(`${environment.apiUrl}api/v1/skills/skillUsers`, params)
      .pipe(
        map( (skillUser: any) => {
          return new SkillUser(skillUser)
        })
      )
  }

  accept(skillUserId: string){
    return this.http.post(`${environment.apiUrl}api/v1/skills/skillUsers/${skillUserId}`, {})
      .pipe(
        map( (skillUser: any) => {
          return new SkillUser(skillUser)
        })
      )
  }

  reject(skillUserId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/skills/skillUsers/${skillUserId}`)
      .pipe(
        map( (skillUser: any) => {
          return new SkillUser(skillUser)
        })
      )
  }

  add(name: string){
    return this.http.post(`${environment.apiUrl}api/v1/skills`, {name: name})
      .pipe(
        map( (skill: any) => {
          return new Skill(skill)
        })
      )
  }

  modify(name: string, skillId: string){
    return this.http.put(`${environment.apiUrl}api/v1/skills/${skillId}`, {name: name})
      .pipe(
        map( (skill: any) => {
          return new Skill(skill)
        })
      )
  }

  delete(skillId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/skills/${skillId}`)
  }
}
