import { createAction, props } from '@ngrx/store';
import { IncidentType } from 'src/app/models/incident-type.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllIncidentTypesAction = createAction(
    '[IncidentTypes] Get all incidentTypes - Loading...',
    props<{currentPage: number, limit: number}>()
);

export const fetchAllIncidentTypesActionSuccess = createAction(
    '[IncidentTypes]  Get all incidentTypes - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllIncidentTypesActionFailed = createAction(
    '[IncidentTypes]  Get all incidentTypes - Failed',
    props<{errors: ErrorApi}>()
);

export const addIncidentTypesAction = createAction(
    '[IncidentTypes]  Add incidentType - Adding...',
    props<{name: string}>()
);

export const addIncidentTypesActionSuccess = createAction(
    '[IncidentTypes]  Add incidentType - Success',
);

export const addIncidentTypesActionFailed = createAction(
    '[IncidentTypes]  Add incidentType - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyIncidentTypesAction = createAction(
    '[IncidentTypes]  Modify incidentType - Modifying...',
    props<{name: string, incidentTypeId: string}>()
);

export const modifyIncidentTypesActionSuccess = createAction(
    '[IncidentTypes]  Modify incidentType - Success',
);

export const modifyIncidentTypesActionFailed = createAction(
    '[IncidentTypes]  Modify incidentType - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteIncidentTypesAction = createAction(
    '[IncidentTypes]  Delete incidentType - Deleting...',
    props<{incidentTypeId: string}>()
);

export const deleteIncidentTypesActionSuccess = createAction(
    '[IncidentTypes]  Delete incidentType - Success',
);

export const deleteIncidentTypesActionFailed = createAction(
    '[IncidentTypes]  Delete incidentType - Failed',
    props<{errors: ErrorApi}>()
);

export const openIncidentTypeDialog = createAction(
    '[IncidentTypes]  Open IncidentType Dialog',
);

export const closeIncidentTypeDialog = createAction(
    '[IncidentTypes]  Close IncidentType Dialog',
);
