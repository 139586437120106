import { createReducer, on } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { addJobsAction, addJobsActionFailed, addJobsActionSuccess, closeJobDialog, deleteJobsAction, deleteJobsActionFailed, deleteJobsActionSuccess, fetchAllJobsAction, fetchAllJobsActionFailed, fetchAllJobsActionSuccess, modifyJobsAction, modifyJobsActionFailed, modifyJobsActionSuccess, openJobDialog } from '../actions/jobs.actions';

export interface StateJobs{
    dataPaginated: DataPaginated | null,
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    adding: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    showJobDialog: boolean,
}
 
export const initialState:StateJobs  = {
    dataPaginated: null,
    loading: null,
    errors: null,
    errorsDialog: null,
    adding: null,
    modifying: null,
    deleting: null,
    showJobDialog: false,
};
  
const _jobsReducer = createReducer(
  initialState,
  on(fetchAllJobsAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllJobsActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllJobsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addJobsAction, (state) =>  ({...state, adding: true })),
  on(addJobsActionSuccess, (state) =>  ({...state, showJobDialog: false, adding: false})),
  on(addJobsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(modifyJobsAction, (state) =>  ({...state, modifying: true })),
  on(modifyJobsActionSuccess, (state) =>  ({...state, showJobDialog: false, modifying: false})),
  on(modifyJobsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteJobsAction, (state) =>  ({...state, deleting: true })),
  on(deleteJobsActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteJobsActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),
  on(openJobDialog, (state) => ({...state, showJobDialog: true })),
  on(closeJobDialog, (state) => ({...state, showJobDialog: false, errorsDialog: null })),
);
 
export function jobReducer(state: any, action: any) {
  return _jobsReducer(state, action);
}