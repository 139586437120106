import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import * as fromRoot from '../reducers';
import { addJobsAction, addJobsActionFailed, addJobsActionSuccess, deleteJobsAction, deleteJobsActionFailed, deleteJobsActionSuccess, fetchAllJobsAction, fetchAllJobsActionFailed, fetchAllJobsActionSuccess, modifyJobsAction, modifyJobsActionFailed, modifyJobsActionSuccess } from '../actions/jobs.actions';
import { JobService } from 'src/app/services/jobs.service';
import { Job } from 'src/app/models/job.model';

@Injectable()
export class JobsEffect {

  fetchAllJobsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllJobsAction),
      exhaustMap(action => 
        this.jobService.all(action.currentPage, action.limit).pipe(
          map( (jobsPaginated: DataPaginated) =>  {
              return fetchAllJobsActionSuccess({dataPaginated: jobsPaginated})}) ,
          catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addJobsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobsAction),
      exhaustMap(action => 
        this.jobService.add(action.name, action.isManager).pipe(
          map( () =>  addJobsActionSuccess()) ,
          catchError( (error) => of(addJobsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllJobsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.jobService.all(1, state.jobs.dataPaginated?.limit ? state.jobs.dataPaginated?.limit : 1).pipe(
            map( (jobsPaginated: DataPaginated) =>  {
              return fetchAllJobsActionSuccess({
                dataPaginated: jobsPaginated
              })
            }),
            catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  modifyJobsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyJobsAction),
      exhaustMap(action => 
        this.jobService.modify(action.name, action.isManager, action.jobId).pipe(
          map( () =>  modifyJobsActionSuccess()) ,
          catchError( (error) => of(modifyJobsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllJobsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyJobsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.jobService.all(state.jobs.dataPaginated?.page ? state.jobs.dataPaginated.page : 1, state.jobs.dataPaginated?.limit ? state.jobs.dataPaginated.limit : 1).pipe(
            map( (jobsPaginated: DataPaginated) =>  {
              return fetchAllJobsActionSuccess({
                dataPaginated: jobsPaginated
              })
            }),
            catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  deleteJobsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJobsAction),
      exhaustMap(action => 
        this.jobService.delete(action.jobId).pipe(
          map( () =>  deleteJobsActionSuccess()) ,
          catchError( (error) => of(deleteJobsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllJobsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteJobsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.jobService.all(state.jobs.dataPaginated?.page ? ((state.jobs.dataPaginated?.page == state.jobs.dataPaginated?.totalPages) && (state.skills.dataPaginated?.docs.length == 1) ? state.jobs.dataPaginated?.page - 1 : state.jobs.dataPaginated?.page) : 1,
                              state.jobs.dataPaginated?.limit ? state.jobs.dataPaginated.limit : 1)
          .pipe(
            map( (jobsPaginated: DataPaginated) =>  {
              return fetchAllJobsActionSuccess({
                dataPaginated: jobsPaginated
              })
            }),
            catchError( (error) => of(fetchAllJobsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
);
 
  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private store$: Store<fromRoot.AppState>
  ) {}
}