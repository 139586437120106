import { createAction, props } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllSkillsAction = createAction(
    '[Skills] Get all skills - Loading...',
    props<{currentPage: number, limit: number}>()
);

export const fetchAllSkillsActionSuccess = createAction(
    '[Skills]  Get all skills - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllSkillsActionFailed = createAction(
    '[Skills]  Get all skills - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllSkillUsersAction = createAction(
    '[SkillUsers] Get all skillUsers - Loading...',
    props<{currentPage: number, limit: number, status?: string, skillType?: string, search?: string}>()
);

export const fetchAllSkillUsersActionSuccess = createAction(
    '[SkillUsers]  Get all skillUsers - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllSkillUsersActionFailed = createAction(
    '[SkillUsers]  Get all skillUsers - Failed',
    props<{errors: ErrorApi}>()
);

export const addSkillsAction = createAction(
    '[Skills]  Add skill - Adding...',
    props<{name: string}>()
);

export const addSkillsActionSuccess = createAction(
    '[Skills]  Add skill - Success',
);

export const addSkillsActionFailed = createAction(
    '[Skills]  Add skill - Failed',
    props<{errors: ErrorApi}>()
);

export const acceptSkillUsersAction = createAction(
    '[SkillUsers]  Accept skillUser - Accepting...',
    props<{skillUserId: string}>()
);

export const acceptSkillUsersActionSuccess = createAction(
    '[SkillUsers]  Accept skillUser - Success',
);

export const acceptSkillUsersActionFailed = createAction(
    '[SkillUsers]  Accept skillUser - Failed',
    props<{errors: ErrorApi}>()
)
;
export const addSkillUsersAction = createAction(
    '[SkillUsers]  Add skillUser - Accepting...',
    props<{skillUserId: string, userId: string, graduationDate: Date }>()
);

export const addSkillUsersActionSuccess = createAction(
    '[SkillUsers]  Add skillUser - Success',
);

export const addSkillUsersActionFailed = createAction(
    '[SkillUsers]  Add skillUser - Failed',
    props<{errors: ErrorApi}>()
);

export const modifySkillsAction = createAction(
    '[Skills]  Modify skill - Modifying...',
    props<{name: string, skillId: string}>()
);

export const modifySkillsActionSuccess = createAction(
    '[Skills]  Modify skill - Success',
);

export const modifySkillsActionFailed = createAction(
    '[Skills]  Modify skill - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteSkillsAction = createAction(
    '[Skills]  Delete skill - Deleting...',
    props<{skillId: string}>()
);

export const deleteSkillsActionSuccess = createAction(
    '[Skills]  Delete skill - Success',
);

export const deleteSkillsActionFailed = createAction(
    '[Skills]  Delete skill - Failed',
    props<{errors: ErrorApi}>()
);

export const rejectSkillUsersAction = createAction(
    '[SkillUsers]  Reject skillUser - Rejecting...',
    props<{skillUserId: string}>()
);

export const rejectSkillUsersActionSuccess = createAction(
    '[SkillUsers]  Reject skillUser - Success',
);

export const rejectSkillUsersActionFailed = createAction(
    '[SkillUsers]  Reject skillUser - Failed',
    props<{errors: ErrorApi}>()
);

export const openSkillDialog = createAction(
    '[Skills]  Open Skill Dialog',
);

export const closeSkillDialog = createAction(
    '[Skills]  Close Skill Dialog',
);
