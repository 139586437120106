import { Deserializable } from './interfaces/deserializable.interface';
import { Platform } from './platform.model';
import { User } from './user.model';

export enum Status {
    all = 'all',
    pending = 'pending',
    validated = 'validated',
    declined = 'declined',
  }

export class DocumentTypeUser implements Deserializable {
    public _id: string;
    public userId: User;
    public documentType: Platform | string;
    public documentTypeFilePath: string;
    public documentTypeUrl: string;
    public status: string;
    public expiryDate: Date;
    public createdAt: Date;

   
    constructor(data: any){
        this._id = data._id;
        this.userId = data.userId;
        this.documentType = data.documentType;
        this.documentTypeFilePath = data.documentTypeFilePath;
        this.documentTypeUrl = data.DocumentTypeUrl;
        this.status = data.status;
        this.expiryDate = data.expiryDate;
        this.createdAt = data.createdAt;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}