import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { Mission } from 'src/app/models/mission/mission.model';
import { MissionJob } from 'src/app/models/mission/missionJob.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { MissionService } from 'src/app/services/missions.service';
import * as fromRoot from '../reducers';
import { addMissionsAction, addMissionsActionFailed, addMissionsActionSuccess, addMissionsJobAction, addMissionsJobActionFailed, addMissionsJobActionSuccess, deleteMissionsAction, deleteMissionsActionFailed, deleteMissionsActionSuccess, deleteMissionsJobAction, deleteMissionsJobActionFailed, deleteMissionsJobActionSuccess, editMissionsAction, editNumberAction, fetchAllMissionsAction, fetchAllMissionsActionFailed, fetchAllMissionsActionSuccess, fetchAllMissionsAppliedAction, fetchAllMissionsAppliedActionFailed, fetchAllMissionsAppliedActionSuccess, fetchAllMissionsJobAction, fetchAllMissionsJobActionFailed, fetchAllMissionsJobActionSuccess, fetchAllTeamAction, fetchAllTeamActionFailed, fetchAllTeamActionSuccess, fetchAllTeamMemberAction, fetchAllTeamMemberActionFailed, fetchAllTeamMemberActionSuccess, modifyMissionsAction, modifyMissionsActionFailed, modifyMissionsActionSuccess, modifyMissionsJobAction, modifyMissionsJobActionFailed, modifyMissionsJobActionSuccess } from '../actions/missions.actions';
import { MissionApplied } from 'src/app/models/mission/missionApplied.model';
import { Team } from 'src/app/models/mission/team.model';
import { TeamMember } from 'src/app/models/mission/teamMember.model';

@Injectable()
export class MissionsEffect {

  fetchAllMissionsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllMissionsAction),
      exhaustMap(action => 
        this.missionService.all(action.currentPage, action.limit, action.isHistory, action.missionStatus).pipe(
          map( (missionsPaginated: DataPaginated) =>  {
              return fetchAllMissionsActionSuccess({dataPaginated: missionsPaginated})}) ,
          catchError( (error) => of(fetchAllMissionsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllMissionsJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllMissionsJobAction),
      exhaustMap(action => 
        this.missionService.allJob(action.missionId).pipe(
          map( (missionsJob: MissionJob[]) =>  {
              return fetchAllMissionsJobActionSuccess({data: missionsJob})}),
          catchError( (error) => of(fetchAllMissionsJobActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );
/*
  fetchAllMissionsAppliedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllMissionsAppliedAction),
      exhaustMap(action => 
        this.missionService.allApplied(action.missionId, action.missionJobId).pipe(
          map( (missionsApplied: MissionApplied[]) =>  {
              return fetchAllMissionsAppliedActionSuccess({data: missionsApplied})}),
          catchError( (error) => of(fetchAllMissionsAppliedActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );
*/
  
  fetchAllTeamAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllTeamAction),
      exhaustMap(action => 
        this.missionService.allTeams(action.missionId).pipe(
          map( (teams: Team[]) =>  {
              return fetchAllTeamActionSuccess({data: teams})}),
          catchError( (error) => of(fetchAllTeamActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllTeamMemberAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllTeamMemberAction),
      exhaustMap(action => 
        this.missionService.allTeamMembers(action.missionId).pipe(
          map( (teamMembers: TeamMember[]) =>  {
              return fetchAllTeamMemberActionSuccess({data: teamMembers})}),
          catchError( (error) => of(fetchAllTeamMemberActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addMissionsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMissionsAction),
      exhaustMap(action => 
        this.missionService.add(action.missionDto).pipe(
          map( (mission: Mission) =>  addMissionsActionSuccess({edited_mission: mission})),
          catchError( (error) => of(addMissionsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addMissionsJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMissionsJobAction),
      exhaustMap(action => 
        this.missionService.addJob(action.missionId, action.missionJobDto).pipe(
          map( () =>  addMissionsJobActionSuccess()),
          catchError( (error) => of(addMissionsJobActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllMissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMissionsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
        this.missionService.all(1, state.missions.dataPaginated?.limit ? state.missions.dataPaginated?.limit : 1).pipe(
            map( (missionsPaginated: DataPaginated) =>  {
              return fetchAllMissionsActionSuccess({
                dataPaginated: missionsPaginated
              })
            }),
            catchError( (error) => of(fetchAllMissionsActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  );

  addAllMissionsJobSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMissionsJobActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
      this.missionService.allJob(state.missions.edited_mission ? state.missions.edited_mission._id : '')
      .pipe(
        map( (jobdata: MissionJob[]) =>  {
          return fetchAllMissionsJobActionSuccess({
            data: jobdata
          })
        }),
        catchError( (error) => of(fetchAllMissionsJobActionFailed({errors: extractErrors(error)})))
      ),
      )
    )
  );

  modifyMissionsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyMissionsAction),
      exhaustMap(action => 
        this.missionService.modify(action.missionId, action.missionDto).pipe(
          map( () =>  modifyMissionsActionSuccess()) ,
          catchError( (error) => of(modifyMissionsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyMissionsJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyMissionsJobAction),
      exhaustMap(action => 
        this.missionService.modifyJob(action.missionId, action.missionJobId, action.missionJobDto).pipe(
          map( () =>  modifyMissionsJobActionSuccess()) ,
          catchError( (error) => of(modifyMissionsJobActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllMissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyMissionsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.missionService.all(state.missions.dataPaginated?.page ? state.missions.dataPaginated.page : 1, state.missions.dataPaginated?.limit ? state.missions.dataPaginated.limit : 1).pipe(
            map( (missionsPaginated: DataPaginated) =>  {
              return fetchAllMissionsActionSuccess({
                dataPaginated: missionsPaginated
              })
            }),
            catchError( (error) => of(fetchAllMissionsActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  modifyAllMissionsJobSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(modifyMissionsJobActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.missionService.allJob(state.missions.edited_mission ? state.missions.edited_mission._id : '')
          .pipe(
            map( (jobdata: MissionJob[]) =>  {
              return fetchAllMissionsJobActionSuccess({
                data: jobdata
              })
            }),
            catchError( (error) => of(fetchAllMissionsJobActionFailed({errors: extractErrors(error)})))
      )
      )
    )
);

  deleteMissionsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMissionsAction),
      exhaustMap(action => 
        this.missionService.delete(action.missionId).pipe(
          map( () =>  deleteMissionsActionSuccess()) ,
          catchError( (error) => of(deleteMissionsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteMissionsJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMissionsJobAction),
      exhaustMap(action => 
        this.missionService.deleteJob(action.missionId, action.missionJobId).pipe(
          map( () =>  deleteMissionsJobActionSuccess()) ,
          catchError( (error) => of(deleteMissionsJobActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllMissionsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteMissionsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.missionService.all(state.missions.dataPaginated?.page ? ((state.missions.dataPaginated?.page == state.missions.dataPaginated?.totalPages) && (state.skills.dataPaginated?.docs.length == 1) ? state.missions.dataPaginated?.page - 1 : state.missions.dataPaginated?.page) : 1,
                              state.missions.dataPaginated?.limit ? state.missions.dataPaginated.limit : 1)
          .pipe(
            map( (missionsPaginated: DataPaginated) =>  {
              return fetchAllMissionsActionSuccess({
                dataPaginated: missionsPaginated
              })
            }),
            catchError( (error) => of(fetchAllMissionsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
);

deleteAllMissionsJobSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteMissionsJobActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.missionService.allJob(state.missions.edited_mission ? state.missions.edited_mission._id : '')
          .pipe(
            map( (jobdata: MissionJob[]) =>  {
              return fetchAllMissionsJobActionSuccess({
                data: jobdata
              })
            }),
            catchError( (error) => of(fetchAllMissionsJobActionFailed({errors: extractErrors(error)})))
      )
      )
    )
);
 
  constructor(
    private actions$: Actions,
    private missionService: MissionService,
    private store$: Store<fromRoot.AppState>
  ) {}
}

