import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Platform } from '../models/platform.model';
import { MeasurementsStatus, OfferTypes, Role, User } from '../models/user.model';
import { DataPaginated } from '../models/utils/data-paginated.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  getMyPlatforms(){
    return this.http.get(`${environment.apiUrl}api/v1/users/me/platforms`)
      .pipe(
        map( (platforms: any) => platforms.map( (platform:any) => new Platform(platform) ))
      );
  }

  changePlatform(platformId: string | null){
    return this.http.put(`${environment.apiUrl}api/v1/users/me/current-platform`, {currentPlatform: platformId} );
  }

  getUsersByRole(role: Role, currentPage?: number, limit?: number, search?: string, start?: string){
    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());
    if(start){
      params = params.append('start_at', start);
    }
    if (search) {
      params = params.append('search', search);
    }

    return this.http.get(`${environment.apiUrl}api/v1/users/role-types/${role}`, {params: params})
      .pipe(
        map( (usersPaginated: any) => {
          const {docs, ...others} = usersPaginated

          return new DataPaginated(
            others,
            docs.map( (user:any) => new User(user))
          );
        }),
      );
  }

  create(body: any){

    return this.http.post(`${environment.apiUrl}api/v1/users`, body)
      .pipe(
        map(user => new User(user))
      )
  }

  importCSV(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiUrl}api/v1/users/import/csv`, formData);
  }

  modify(user: User, body: any){
    return this.http.put(`${environment.apiUrl}api/v1/users/${user._id}`, body).pipe(map(() => user))
  }

  modifyMeasurements(user: User, body: any){
    return this.http.patch(`${environment.apiUrl}api/v1/users/${user._id}`, body).pipe(map(() => user))
  }

  delete(user: User){
    return this.http.delete(`${environment.apiUrl}api/v1/users/${user._id}`).pipe(map(() => user));
  }

}
