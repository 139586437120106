import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { IncidentType } from '../models/incident-type.model';
import { DataPaginated } from '../models/utils/data-paginated.model';

@Injectable({
  providedIn: 'root'
})
export class IncidentTypeService {

  constructor(private http: HttpClient) {}

  all(currentPage: number, limit: number){
    
    let params = new HttpParams();
    params = params.append('page', !currentPage ? "1" : currentPage.toString());
    params = params.append('limit', !limit ? "10" : limit.toString());

    return this.http.get(`${environment.apiUrl}api/v1/incident-types`, {params: params})
      .pipe(
        map( (incidentTypes_paginated: any) => {
          const {docs, ...others} = incidentTypes_paginated
          return new DataPaginated(
            others,
            docs.map( (incidentType:any) => new IncidentType(incidentType))
          );
        }),
      );
  }

  add(name: string){
    return this.http.post(`${environment.apiUrl}api/v1/incident-types`, {name: name})
      .pipe(
        map( (incidentType: any) => { 
          return new IncidentType(incidentType)
        })
      )
  }

  modify(name: string, incidentTypeId: string){
    return this.http.put(`${environment.apiUrl}api/v1/incident-types/${incidentTypeId}`, {name: name})
      .pipe(
        map( (incidentType: any) => { 
          return new IncidentType(incidentType)
        })
      )
  }

  delete(incidentTypeId: string){
    return this.http.delete(`${environment.apiUrl}api/v1/incident-types/${incidentTypeId}`)
  }
}