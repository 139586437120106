import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { extractErrors } from 'src/app/helpers/errorsHelper';
import { Status, Ticket } from 'src/app/models/ticket.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { TicketService } from 'src/app/services/tickets.service';
import * as fromRoot from '../reducers';
import { addTicketsAction, addTicketsActionFailed, addTicketsActionSuccess, deleteTicketsAction, deleteTicketsActionFailed, deleteTicketsActionSuccess, fetchAllTicketsAction, fetchAllTicketsActionFailed, fetchAllTicketsActionSuccess, modifyTicketsAction, modifyTicketsActionFailed, modifyTicketsActionSuccess } from '../actions/tickets.actions';

@Injectable()
export class TicketsEffect {

  fetchAllTicketsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllTicketsAction),
      exhaustMap(action => 
        this.ticketService.all(action.currentPage, action.limit, action.status).pipe(
          map( (ticketsPaginated: DataPaginated) =>  {
              return fetchAllTicketsActionSuccess({dataPaginated: ticketsPaginated})}) ,
          catchError( (error) => of(fetchAllTicketsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addTicketsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTicketsAction),
      exhaustMap(action => 
        this.ticketService.add(action.name).pipe(
          map( () =>  addTicketsActionSuccess()) ,
          catchError( (error) => of(addTicketsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addAllTicketsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTicketsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.ticketService.all(1, state.tickets.dataPaginated?.limit ? state.tickets.dataPaginated?.limit : 1, Status.done).pipe(
            map( (ticketsPaginated: DataPaginated) =>  {
              return fetchAllTicketsActionSuccess({
                dataPaginated: ticketsPaginated
              })
            }),
            catchError( (error) => of(fetchAllTicketsActionFailed({errors: extractErrors(error)})))
          ),
      )
    )
  )

  modifyTicketsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyTicketsAction),
      exhaustMap(action => 
        this.ticketService.modify(action.data, action.ticketId).pipe(
          map( () =>  modifyTicketsActionSuccess({status: action.status})) ,
          catchError( (error) => of(modifyTicketsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyAllTicketsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyTicketsActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(([action, state]) => 
          this.ticketService.all(state.tickets.dataPaginated?.page ? state.tickets.dataPaginated.page : 1, state.tickets.dataPaginated?.limit ? state.tickets.dataPaginated.limit : 1, action.status).pipe(
            map( (ticketsPaginated: DataPaginated) =>  {
              return fetchAllTicketsActionSuccess({
                dataPaginated: ticketsPaginated
              })
            }),
            catchError( (error) => of(fetchAllTicketsActionFailed({errors: extractErrors(error)})))
        )
        )
      )
  );

  deleteTicketsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTicketsAction),
      exhaustMap(action => 
        this.ticketService.delete(action.ticketId).pipe(
          map( () =>  deleteTicketsActionSuccess({status: action.status})) ,
          catchError( (error) => of(deleteTicketsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteAllTicketsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteTicketsActionSuccess),
    withLatestFrom(this.store$),
    exhaustMap(([action, state]) => 
        this.ticketService.all(state.tickets.dataPaginated?.page ? ((state.tickets.dataPaginated?.page == state.tickets.dataPaginated?.totalPages) && (state.tickets.dataPaginated?.docs.length == 1) ? state.tickets.dataPaginated?.page - 1 : state.tickets.dataPaginated?.page) : 1,
                              state.tickets.dataPaginated?.limit ? state.tickets.dataPaginated.limit : 1, action.status)
          .pipe(
            map( (ticketsPaginated: DataPaginated) =>  {
              return fetchAllTicketsActionSuccess({
                dataPaginated: ticketsPaginated
              })
            }),
            catchError( (error) => of(fetchAllTicketsActionFailed({errors: extractErrors(error)})))
      )
      )
    )
  );
 
  constructor(
    private actions$: Actions,
    private ticketService: TicketService,
    private store$: Store<fromRoot.AppState>
  ) {}
}