import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {extractErrors} from 'src/app/helpers/errorsHelper';
import {DataPaginated} from 'src/app/models/utils/data-paginated.model';
import * as fromRoot from '../reducers';
import {
  addUserAction,
  addUserActionSuccess,
  addUsersActionFailed,
  deleteUserAction,
  deleteUserActionFailed,
  deleteUserActionSuccess,
  fetchAllAdminsAction,
  fetchAllAdminsActionFailed,
  fetchAllAdminsActionSuccess,
  fetchAllSuperAdminsAction,
  fetchAllSuperAdminsActionFailed,
  fetchAllSuperAdminsActionSuccess,
  fetchAllUsersAction,
  fetchAllUsersActionFailed,
  fetchAllUsersActionSuccess,
  modifyUserAction,
  modifyUserActionSuccess, modifyUserMeasurementsAction,
  modifyUsersActionFailed
} from '../actions/users.actions';
import {UserService} from 'src/app/services/user.service';
import {Role} from 'src/app/models/user.model';
import {ErrorApi} from 'src/app/models/utils/error-api.model';
import {UserPermissionsService} from '../../services/user-permissions.service';

@Injectable()
export class UsersEffect {

  fetchAllSuperAdminAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllSuperAdminsAction),
      exhaustMap(action =>
        this.userService.getUsersByRole(Role.superAdmin, action.currentPage, action.limit, action.search).pipe(
          map((usersPaginated: DataPaginated) => {
            return fetchAllSuperAdminsActionSuccess({dataPaginated: usersPaginated});
          }), catchError((error) => of(fetchAllSuperAdminsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllAdminAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllAdminsAction),
      exhaustMap(action =>
        this.userService.getUsersByRole(Role.admin, action.currentPage, action.limit, action.search).pipe(
          map((usersPaginated: DataPaginated) => {
            return fetchAllAdminsActionSuccess({dataPaginated: usersPaginated});
          }), catchError((error) => of(fetchAllAdminsActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  fetchAllUsersAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllUsersAction),
      exhaustMap(action =>
        this.userService.getUsersByRole(Role.user, action.currentPage, action.limit, action.search).pipe(
          map((usersPaginated: DataPaginated) => {
            return fetchAllUsersActionSuccess({dataPaginated: usersPaginated});
          }), catchError((error) => of(fetchAllUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserAction),
      exhaustMap(action =>
        this.userService.create(action.body).pipe(
          map((user) => addUserActionSuccess({user: user})),
          catchError((error) => of(addUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  addUserActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(async ([action, state]) => {
          if (action.user.role === Role.user) {
            const page = state.users.usersPaginated?.page;
            const limit = state.users.usersPaginated?.limit;
            return fetchAllUsersAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.admin) {
            const page = state.users.adminsPaginated?.page;
            const limit = state.users.adminsPaginated?.limit;
            return fetchAllAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.superAdmin) {
            const page = state.users.superAdminsPaginated?.page;
            const limit = state.users.superAdminsPaginated?.limit;
            return fetchAllSuperAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else {
            return fetchAllUsersActionFailed({errors: new ErrorApi({msg: 'Impossible de rafraîchir la liste des utilisateurs !'})});
          }
        }
      )
    )
  );

  modifyUserMeasurementsAction$ = createEffect(() =>
  
    this.actions$.pipe(
      ofType(modifyUserMeasurementsAction),
      exhaustMap(action =>
        this.userService.modifyMeasurements(action.user, action.body).pipe(
          map((user) => modifyUserActionSuccess({user: user})),
          catchError((error) => of(modifyUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyUserAction),
      exhaustMap(action =>
        this.userService.modify(action.user, action.body).pipe(
          map((user) => modifyUserActionSuccess({user: user})),
          catchError((error) => of(modifyUsersActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  modifyUserActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(modifyUserActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(async ([action, state]) => {
          if (action.user.role === Role.user) {
            const page = state.users.usersPaginated?.page;
            const limit = state.users.usersPaginated?.limit;
            return fetchAllUsersAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.admin) {
            const page = state.users.adminsPaginated?.page;
            const limit = state.users.adminsPaginated?.limit;
            return fetchAllAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.superAdmin) {
            const page = state.users.superAdminsPaginated?.page;
            const limit = state.users.superAdminsPaginated?.limit;
            return fetchAllSuperAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else {
            return fetchAllUsersActionFailed({errors: new ErrorApi({msg: 'Impossible de rafraîchir la liste des utilisateurs !'})});
          }
        }
      )
    )
  );

  deleteUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserAction),
      exhaustMap(action =>
        this.userService.delete(action.user).pipe(
          map((user) => deleteUserActionSuccess({user: user})),
          catchError((error) => of(deleteUserActionFailed({errors: extractErrors(error)})))
        )
      )
    )
  );

  deleteUserActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserActionSuccess),
      withLatestFrom(this.store$),
      exhaustMap(async ([action, state]) => {
          if (action.user.role === Role.user) {
            const page = state.users.usersPaginated?.page;
            const limit = state.users.usersPaginated?.limit;
            return fetchAllUsersAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.admin) {
            const page = state.users.adminsPaginated?.page;
            const limit = state.users.adminsPaginated?.limit;
            return fetchAllAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else if (action.user.role === Role.superAdmin) {
            const page = state.users.superAdminsPaginated?.page;
            const limit = state.users.superAdminsPaginated?.limit;
            return fetchAllSuperAdminsAction({currentPage: page ? page : 1, limit: limit ? limit : 10});
          } else {
            return fetchAllUsersActionFailed({errors: new ErrorApi({msg: 'Impossible de rafraîchir la liste des utilisateurs !'})});
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userPermissionsService: UserPermissionsService,
    private store$: Store<fromRoot.AppState>
  ) {
  }
}
