import { createAction, props } from '@ngrx/store';
import { DocumentType } from 'src/app/models/document-type.model';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';

export const fetchAllDocumentTypesAction = createAction(
    '[DocumentTypes] Get all document-types - Loading...',
    props<{currentPage: number, limit: number}>()
);

export const fetchAllDocumentTypesActionSuccess = createAction(
    '[DocumentTypes]  Get all document-types - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllDocumentTypesActionFailed = createAction(
    '[DocumentTypes]  Get all document-types - Failed',
    props<{errors: ErrorApi}>()
);

export const fetchAllDocumentTypeUsersAction = createAction(
    '[DocumentTypeUsers] Get all documentTypeUsers - Loading...',
    props<{currentPage: number, limit: number, status?: string, documentType?: string, search?: string}>()
);

export const fetchAllDocumentTypeUsersActionSuccess = createAction(
    '[DocumentTypeUsers]  Get all documentTypeUsers - Success',
    props<{dataPaginated: DataPaginated}>()
);

export const fetchAllDocumentTypeUsersActionFailed = createAction(
    '[DocumentTypeUsers]  Get all documentTypeUsers - Failed',
    props<{errors: ErrorApi}>()
);

export const addDocumentTypesAction = createAction(
    '[DocumentTypes]  Add document-type - Adding...',
    props<{name: string, require: boolean, expiryDateRequire: boolean}>()
);

export const addDocumentTypesActionSuccess = createAction(
    '[DocumentTypes]  Add document-type - Success',
);

export const addDocumentTypesActionFailed = createAction(
    '[DocumentTypes]  Add document-type - Failed',
    props<{errors: ErrorApi}>()
);

export const acceptDocumentTypeUsersAction = createAction(
    '[DocumentTypeUsers]  Accept documentTypeUser - Accepting...',
    props<{documentTypeUserId: string}>()
);

export const acceptDocumentTypeUsersActionSuccess = createAction(
    '[DocumentTypeUsers]  Accept documentTypeUser - Success',
);

export const acceptDocumentTypeUsersActionFailed = createAction(
    '[DocumentTypeUsers]  Accept documentTypeUser - Failed',
    props<{errors: ErrorApi}>()
);

export const modifyDocumentTypesAction = createAction(
    '[DocumentTypes]  Modify document-type - Modifying...',
    props<{name: string, require: boolean, expiryDateRequire:boolean, documentTypeId: string}>()
);

export const modifyDocumentTypesActionSuccess = createAction(
    '[DocumentTypes]  Modify document-type - Success',
);

export const modifyDocumentTypesActionFailed = createAction(
    '[DocumentTypes]  Modify document-type - Failed',
    props<{errors: ErrorApi}>()
);

export const deleteDocumentTypesAction = createAction(
    '[DocumentTypes]  Delete document-type - Deleting...',
    props<{documentTypeId: string}>()
);

export const deleteDocumentTypesActionSuccess = createAction(
    '[DocumentTypes]  Delete document-type - Success',
);

export const deleteDocumentTypesActionFailed = createAction(
    '[DocumentTypes]  Delete document-type - Failed',
    props<{errors: ErrorApi}>()
);

export const rejectDocumentTypeUsersAction = createAction(
    '[DocumentTypeUsers]  Reject documentTypeUser - Rejecting...',
    props<{documentTypeUserId: string}>()
);

export const rejectDocumentTypeUsersActionSuccess = createAction(
    '[DocumentTypeUsers]  Reject documentTypeUser - Success',
);

export const rejectDocumentTypeUsersActionFailed = createAction(
    '[DocumentTypeUsers]  Reject documentTypeUser - Failed',
    props<{errors: ErrorApi}>()
);

export const openDocumentTypeDialog = createAction(
    '[DocumentTypes]  Open DocumentType Dialog',
);

export const closeDocumentTypeDialog = createAction(
    '[DocumentTypes]  Close DocumentType Dialog',
);