import {createAction, props} from '@ngrx/store';
import {DataPaginated} from '../../models/utils/data-paginated.model';
import {ErrorApi} from '../../models/utils/error-api.model';

export const fetchAllPrepaysAction = createAction(
  '[Prepays] Get all prepays - Loading...',
  props<{currentPage: number, limit: number, startDate?: Date , endDate?: Date }>()
);

export const fetchAllPrepaysActionSuccess = createAction(
  '[Prepays]  Get all prepays - Success',
  props<{dataPaginated: DataPaginated}>()
);

export const fetchAllPrepaysActionFailed = createAction(
  '[Prepays]  Get all prepays - Failed',
  props<{errors: ErrorApi}>()
);
