import {Deserializable} from './interfaces/deserializable.interface';
import {User} from './user.model';
import {Platform} from './platform.model';

export enum Permission {
  SKILL_READ = "skill_read",
  SKILL_CREATE = "skill_create",
  SKILL_EDIT = "skill_edit",
  SKILL_DELETE = "skill_delete",
  SKILL_VALID = "skill_valid",
  DOCUMENT_READ = "document_read",
  DOCUMENT_CREATE = "document_create",
  DOCUMENT_EDIT = "document_edit",
  DOCUMENT_DELETE = "document_delete",
  DOCUMENT_VALID = "document_valid",
  INCIDENT_READ = "incident_read",
  INCIDENT_CREATE = "incident_create",
  INCIDENT_EDIT = "incident_edit",
  INCIDENT_DELETE = "incident_delete",
  MISSION_READ = "mission_read",
  MISSION_CREATE = "mission_create",
  MISSION_EDIT = "mission_edit",
  MISSION_DELETE = "mission_delete",
  MISSION_MANAGEMENT_JOB = "mission_management_job",
  MISSION_MANAGEMENT_SOURCING = "mission_management_sourcing",
  MISSION_MANAGEMENT_TEAM = "mission_management_team",
  MISSION_ACCESS = "mission_access",
  JOB_READ = "job_read",
  JOB_CREATE = "job_create",
  JOB_EDIT = "job_edit",
  JOB_DELETE = "job_delete",
  USER_READ = "user_read",
  USER_CREATE = "user_create",
  USER_EDIT = "user_edit",
  USER_DELETE = "user_delete",
  ADMIN_READ = "admin_read",
  ADMIN_CREATE = "admin_create",
  ADMIN_EDIT = "admin_edit",
  ADMIN_DELETE = "admin_delete",
  SUPPORT_READ = "support_read",
  SUPPORT_CREATE = "support_create",
  SUPPORT_EDIT = "support_edit",
  SUPPORT_DELETE = "support_delete",
  PREPAY_READ = "prepay_read"
}

export class UserPermission implements Deserializable {
  public _id: string;
  public userId: User |string;
  public platformId: Platform | string;
  public permissions: string[];

  constructor(data: any) {
    this._id = data._id;
    this.userId = data.userId;
    this.platformId = data.platformId;
    this.permissions = data.permissions;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
