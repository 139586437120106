import { createReducer, on } from '@ngrx/store';
import { DataPaginated } from 'src/app/models/utils/data-paginated.model';
import { ErrorApi } from 'src/app/models/utils/error-api.model';
import { addIncidentTypesAction, addIncidentTypesActionFailed, addIncidentTypesActionSuccess, closeIncidentTypeDialog, deleteIncidentTypesAction, deleteIncidentTypesActionFailed, deleteIncidentTypesActionSuccess, fetchAllIncidentTypesAction, fetchAllIncidentTypesActionFailed, fetchAllIncidentTypesActionSuccess, modifyIncidentTypesAction, modifyIncidentTypesActionFailed, modifyIncidentTypesActionSuccess, openIncidentTypeDialog } from '../actions/incident-types.actions';

export interface StateIncidentTypes{
    dataPaginated: DataPaginated | null,
    loading: boolean | null,
    errors: ErrorApi | null,
    errorsDialog: ErrorApi | null,
    adding: boolean | null,
    modifying: boolean | null,
    deleting: boolean | null,
    showIncidentTypeDialog: boolean,
}
 
export const initialState:StateIncidentTypes  = {
    dataPaginated: null,
    loading: null,
    errors: null,
    errorsDialog: null,
    adding: null,
    modifying: null,
    deleting: null,
    showIncidentTypeDialog: false,
};
  
const _incidentTypesReducer = createReducer(
  initialState,
  on(fetchAllIncidentTypesAction, (state) => ({...state, loading: true, list: null, errors :null})),
  on(fetchAllIncidentTypesActionSuccess, (state, payload) =>  ({...state, dataPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllIncidentTypesActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false })),
  on(addIncidentTypesAction, (state) =>  ({...state, adding: true })),
  on(addIncidentTypesActionSuccess, (state) =>  ({...state, showIncidentTypeDialog: false, adding: false})),
  on(addIncidentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, adding: false })),
  on(modifyIncidentTypesAction, (state) =>  ({...state, modifying: true })),
  on(modifyIncidentTypesActionSuccess, (state) =>  ({...state, showIncidentTypeDialog: false, modifying: false})),
  on(modifyIncidentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, modifying: false })),
  on(deleteIncidentTypesAction, (state) =>  ({...state, deleting: true })),
  on(deleteIncidentTypesActionSuccess, (state) =>  ({...state, deleting: false})),
  on(deleteIncidentTypesActionFailed, (state, payload) => ({...state, errorsDialog: payload.errors, deleting: false })),
  on(openIncidentTypeDialog, (state) => ({...state, showIncidentTypeDialog: true })),
  on(closeIncidentTypeDialog, (state) => ({...state, showIncidentTypeDialog: false, errorsDialog: null })),
);
 
export function incidentTypeReducer(state: any, action: any) {
  return _incidentTypesReducer(state, action);
}