import {createReducer, on} from '@ngrx/store';
import {User} from 'src/app/models/user.model';
import {DataPaginated} from 'src/app/models/utils/data-paginated.model';
import {ErrorApi} from 'src/app/models/utils/error-api.model';
import {
  fetchAllAdminsAction,
  fetchAllAdminsActionFailed,
  fetchAllAdminsActionSuccess,
  fetchAllSuperAdminsAction,
  fetchAllSuperAdminsActionFailed,
  fetchAllSuperAdminsActionSuccess,
  fetchAllUsersAction,
  fetchAllUsersActionFailed,
  fetchAllUsersActionSuccess,
  closeDialogUser,
  showDialogUser,
  closeDialogAddUserSkill,
  showDialogAddUserSkill,
  addUserAction,
  addUserActionSuccess,
  addUsersActionFailed,
  deleteUserAction,
  deleteUserActionSuccess,
  deleteUserActionFailed,
  modifyUserActionSuccess,
  modifyUserAction,
  modifyUsersActionFailed, modifyUserMeasurementsAction,
} from '../actions/users.actions';

export interface StateUsers {
  superAdminsPaginated: DataPaginated | null,
  adminsPaginated: DataPaginated | null,
  usersPaginated: DataPaginated | null,
  loading: boolean | null,
  errors: ErrorApi | null,
  showDialog: boolean,
  showDialogAdd: boolean,
  errorsDialog: ErrorApi | null,
  adding: User | null,
  modifying: User | null,
  deleting: User | null,
}

export const initialState: StateUsers = {
  superAdminsPaginated: null,
  adminsPaginated: null,
  usersPaginated: null,
  loading: null,
  errors: null,
  showDialog: false,
  showDialogAdd: false,
  errorsDialog: null,
  adding: null,
  modifying: null,
  deleting: null,
};

const _usersReducer = createReducer(
  initialState,
  on(fetchAllSuperAdminsAction, (state) => ({...state, loading: true, superAdminsPaginated: null})),
  on(fetchAllSuperAdminsActionSuccess, (state, payload) => ({...state, superAdminsPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllSuperAdminsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false})),

  on(fetchAllAdminsAction, (state) => ({...state, loading: true, adminsPaginated: null})),
  on(fetchAllAdminsActionSuccess, (state, payload) => ({...state, adminsPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllAdminsActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false})),

  on(fetchAllUsersAction, (state) => ({...state, loading: true, usersPaginated: null})),
  on(fetchAllUsersActionSuccess, (state, payload) => ({...state, usersPaginated: payload.dataPaginated, loading: false})),
  on(fetchAllUsersActionFailed, (state, payload) => ({...state, errors: payload.errors, loading: false})),

  on(addUserAction, (state) => ({...state, loading: true})),
  on(addUserActionSuccess, (state, payload) => ({...state, loading: false, adding: payload.user})),
  on(addUsersActionFailed, (state, payload) => ({...state, loading: false, errorsDialog: payload.errors, adding: null})),

  on(modifyUserAction, (state) => ({...state, loading: true})),
  on(modifyUserMeasurementsAction, (state) => ({...state, loading: true})),
  on(modifyUserActionSuccess, (state, payload) => ({...state, loading: false, modifying: payload.user})),
  on(modifyUsersActionFailed, (state, payload) => ({...state, loading: false, errorsDialog: payload.errors, modifying: null})),

  on(deleteUserAction, (state) => ({...state, loading: true})),
  on(deleteUserActionSuccess, (state, payload) => ({...state, loading: false, deleting: payload.user})),
  on(deleteUserActionFailed, (state, payload) => ({...state, loading: false, errorsDialog: payload.errors, deleting: null})),

  on(showDialogUser, (state) => ({...state, showDialog: true})),
  on(closeDialogUser, (state) => ({...state, showDialog: false})),
  
  on(showDialogAddUserSkill, (state) => ({...state, showDialogAdd: true})),
  on(closeDialogAddUserSkill, (state) => ({...state, showDialogAdd: false}))
);

export function usersReducer(state: any, action: any) {
  return _usersReducer(state, action);
}
