import { Deserializable } from "../interfaces/deserializable.interface";
import { Job } from "../job.model";
import { Skill } from "../skill.model";

export class MissionJob implements Deserializable {
    public _id: string;
    public missionId: string;
    public job: Job;
    public description: string;
    public peopleNumber: number;
    public pricePerHour: number;
    public instruction: string;
    public skills: Array<Skill>;
    public startDate: Date;
    public endDate: Date;

    constructor(data: any){
        this._id = data._id;
        this.job = data.job;
        this.description = data.description;
        this.peopleNumber = data.peopleNumber;
        this.pricePerHour = data.pricePerHour;
        this.instruction = data.instruction;
        this.skills = data.skills;
        this.startDate = new Date(data.startDate);
        this.endDate = new Date(data.endDate);
        
        if (!data.mission._id){
            this.missionId = data.mission;
        }
        else{ this.missionId = data.mission._id }
   
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

}